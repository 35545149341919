<template>
  <div style="height: 100%">
    <div class="block">
      <div>
        <div class="banner-box">
          <img
            v-if="detailList.images != undefined && detailList.images != ''"
            :src="detailList.images"
            alt=""
          />
          <img
            v-else
            src="../assets/img/bg/新闻中心.jpg"
            alt=""
            :height="bannerHeight + 'px'"
          />
        </div>
      </div>
    </div>
    <div class="list">
      <div class="list-title">
        <ol class="crumb">
          <li>
            <a href="/">首页</a>
          </li>
          <li
            style="color: #337ab7; cursor: pointer"
            @click="goBack()"
            v-if="category.name"
          >
            {{ category.name }}
          </li>
          <li>详情</li>
        </ol>
      </div>
      <div class="content-detail">
        <div class="content-list flex">
          <div class="detail-left">
            <!-- <div class="recommend">
              <div class="re-title">最新推荐</div>
              <ul class="re-list">
                <li
                  v-for="item in newList"
                  :key="item.id"
                  @click="goTo(item.id)"
                >
                  {{ item.title }}
                </li>
              </ul>
            </div> -->
            
          </div>
          <div class="detail-rig">
            <div class="right-content">
              <h3 class="right-title">{{ detailList.title }}</h3>
              <div class="author">
                <span style="margin-right: 8px" v-if="user.name"
                  >作者：&nbsp;{{ user.name }}</span
                >
                <span>时间：&nbsp;{{ detailList.createDate }}</span>
              </div>
              <div class="right-article" v-html="articleData.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footern></footern>
  </div>
</template>

<script>
import Footern from "../components/FooterNew";

import navbar1 from "../assets/img/img1.png";
import navbar2 from "../assets/img/navbar4.jpg";
import navbar3 from "../assets/img/navbar2.jpg";
import navbar4 from "../assets/img/navbar3.jpg";
import { detailApi } from "../api/detail";

export default {
  name: "detail",
  components: { Footern},

  data() {
    return {
      defaultImg: require("../assets/img/img1.png"),
      loading: false,
      pdresult: false,
      navbar: [navbar1, navbar2, navbar3, navbar4],
      bannerHeight: "",
      screenWidth: "",
      screenHeight: "",
      cId: "",
      tId: "",
      name: "",
      content: "",
      title: "",
      detailList: {},
      category: { name: "" },
      newList: {},
      articleData: {},
      user: { name: "" },
    };
  },
  computed: {},
  created() {
    this.tId = this.$route.query.id;
    // this.tId = this.$route.params.id;
    this.getDetail();
  },
  watch: {
    $route() {
      if (this.$route.query.id != undefined) {
        this.tId = this.$route.query.id;
        this.getDetail();
      }
    },
  },
  methods: {
    
    async getDetail() {
      this.loading = true;
      const result = await detailApi(this.tId, this.title, this.content);

      this.detailList = result;
      this.category = result.category;
      this.newList = result.newList;
      this.articleData = result.articleData;
      this.user = result.user;
    },
    goTo(val) {
      //直接跳转
      this.$router.push({
        path: "/detail",
        query: { id: val },
        // name: "detail",
        // params: { id: val }
      });
    },
    goBack(val) {
      this.$router.push({ path: "/list", query: { c: val } });
    },
    imgLoad() {
      this.$nextTick(() => {
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      });
    },
  },
  mounted() {
    this.pdresult = window.matchMedia("(min-width: 400px)").matches;

    this.screenWidth = document.body.clientWidth;
    this.bannerHeight = parseInt(this.screenWidth / 3.5);

    this.imgLoad();

    window.addEventListener(
      "resize",
      () => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      },
      false
    );
  },
};
</script>
<style>
html,
* {
  padding: 0;
  margin-left: 0;
}
html,
body {
  height: 100%;
}
.banner-box {
  height: 400px;
  width: 100%;
}
.banner-box img {
  width: 100%;
  height: 100%;
}
.flexr {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.banner2 {
  display: none;
}

.flex {
  display: flex;
  justify-content: space-around;
}
.content-detail {
  width: 100%;
  margin: 10px auto;
  height: 100%;
}
.content-list {
  width: 100%;
  height: 100%;
}
.recommend {
  width: 100%;
  height: 100%;
}
.recommend > .re-title {
  height: 36px;
  line-height: 36px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  background-color: #f0f0f0;
}
.re-title {
  width: 100%;

  font-size: 18px;
}
.re-list {
  width: 100%;
  height: 100%;
  list-style-type: none;
}
.re-list li {
  height: 42px;
  line-height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 6px;
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  color: #337ab7;
  font-size: 16px;
  cursor: pointer;
}

.detail-left {
  width: 24%;
  border: 1px solid #ddd;
  height: 100%;
  border-radius: 4px;
  height: 0;
}
.detail-rig {
  width: 74%;
  border: 1px solid #ddd;
  height: 100%;
  min-height: 700px;
  border-radius: 4px;
}
.right-content {
  width: 100%;
  padding: 30px;
}
.author {
  margin: 24px auto;
  text-align: center;
}

.right-title {
  text-align: center;
}
.right-article {
  margin: 0 auto;
  width: 100%;
}
@media (max-width: 980px) {
  .content-list {
    flex-direction: column;
  }
  .detail-left {
    width: 100%;
  }
  .detail-rig {
    margin-top: 20px;
    width: 100%;
  }
}
@media (max-width: 860px) {
}
@media (max-width: 768px) {
  .detail-rig {
    margin-top: 20px;
    width: 100%;
  }
  .re-list {
    margin-bottom: 0;
  }
}

@media (max-width: 560px) {
}
@media (max-width: 480px) {
  .content-list {
    flex-direction: column;
  }
}
@media (max-width: 400px) {
}
</style>
