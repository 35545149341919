import request from '@/utils/request';

export async function contactApi() {
  const response = await request.get('/f/contactus', {
    params: {}
  });
  return response;
}
export async function departmentApi() {
  const response = await request.get('/f/department', {
    params: {}
  });
  return response;
}
