<template>
  <div>
    <Header></Header>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Header2";
import Footer from "../components/Footer";

export default {
  name: "home",
  components: { Header, Footer },
  data() {
    return {};
  }
};
</script>

<style>
</style>