<template>
  <div style="height:100%;">
    <div class="block">
      <div>
        <div class="banner-box">
          <img
            v-if="journalList.images != undefined && detailList.images != ''"
            :src="journalList.images"
            alt
          />
          <img v-else src="../assets/img/img1.png" alt />
        </div>
      </div>
    </div>
    <div class="list">
      <div class="list-title">
        <ol class="crumb">
          <li>
            <a href="/">首页</a>
          </li>
          <li>{{ journalData.desc }}</li>
        </ol>
      </div>
      <div class="mag-list">
        <div class="mag-item" v-for="item in journalList" :key="item.id">
          <div class="mag-img">
            <img
              :src="address + item.img"
              err-src="../assets/img/bg1.png"
              style="width:100%;height:100%"
            />
            <div class="mask">
              <a
                class="btn btn-warning"
                target="_blank"
                :href="address + item.pdfFile"
                role="button"
              >预览</a>
              <a class="btn btn-info" :href="item.pdfFile" download role="button">下载</a>
            </div>
          </div>
          <div class="mag-title">{{ item.titleCn }}</div>
        </div>
      </div>
    </div>
    <div class="bottom-page">
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="this.pageSize"
          layout="prev, pager, next, jumper"
          :total="this.count"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import navbar1 from "../assets/img/img1.png";
import navbar2 from "../assets/img/navbar4.jpg";
import navbar3 from "../assets/img/navbar2.jpg";
import navbar4 from "../assets/img/navbar3.jpg";
import { journalApi } from "../api/journal";

export default {
  name: "magezine",
  data() {
    return {
      address: "http://www.primaopto.com",
      pdresult: false,
      navbar: [navbar1, navbar2, navbar3, navbar4],
      bannerHeight: "",
      screenWidth: "",
      screenHeight: "",
      mId: "",
      pageNo: 1,
      pageSize: 10,
      journalList: [],
      journalData: {},
      currentPage: 1,
      count: 10
    };
  },
  computed: {},
  created() {
    this.mId = this.$route.query.c;
    this.getJournal();
  },

  watch: {
    $route() {
      if (this.$route.query.c != undefined && this.$route.path == "/magezine") {
        this.mId = this.$route.query.c;
        this.getJournal();
      }
    }
  },

  methods: {
    async getJournal() {
      const result = await journalApi(this.pageNo, this.pageSize);
      this.journalData = result;
      this.count = result.count;
      this.journalList = result.list;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getJournal();
    },
    imgLoad() {
      this.$nextTick(() => {
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      });
    }
  },
  mounted() {
    this.pdresult = window.matchMedia("(min-width: 400px)").matches;
    this.screenWidth = document.body.clientWidth;
    this.bannerHeight = parseInt(this.screenWidth / 3.5);
    this.imgLoad();
    window.addEventListener(
      "resize",
      () => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      },
      false
    );
  }
};
</script>
<style>
html,
* {
  padding: 0;
  margin-left: 0;
}
html,
body {
  height: 100%;
}
.flexr {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.list {
  width: 80%;
}
.banner2 {
  display: none;
}

.flex {
  display: flex;
  justify-content: space-around;
}
.content-detail {
  width: 100%;
  margin: 10px auto;
  height: 100%;
}
.mag-list {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 20px;
}
.mag-item {
  width: 20%;

  padding: 0px 10px 16px;
}
.mag-img {
  position: relative;
  width: 100%;
  height: 300px;
  text-align: center;
}
.mag-img:hover .mask {
  opacity: 1;
}
.mask {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding-top: 90px;
  width: 100%;
  height: 100%;
  background: rgba(101, 101, 101, 0.6);
  color: #ffffff;
}
.btn-info {
  background: #5bc0de;
  color: #fff;
}
.btn-warning {
  background: #f0ad4e;
  color: #fff;
}
.mask a {
  margin: 10px auto;
  width: 100px;
}
.mask .btn {
  display: block;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 4px;
}
.mag-title {
  text-align: center;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
}
@media (max-width: 1600px) {
  .mag-img {
    height: 230px;
  }
}
@media (max-width: 960px) {
  .mag-img {
    height: 200px;
  }
  .mag-title {
    font-size: 14px;
  }
}
@media (max-width: 860px) {
  .mag-img {
    height: 150px;
  }
  .mag-item {
    width: 25%;

    padding: 10px 10px 16px;
  }
  .mask a {
    width: 80px;
  }
  .mask {
    padding-top: 40px;
  }
}
@media (max-width: 768px) {
}

@media (max-width: 560px) {
}
@media (max-width: 480px) {
  .mag-item {
    width: 50%;
    padding: 0 10px 16px;
  }
  .mag-title {
    font-size: 14px;
    line-height: inherit;
  }
}
@media (max-width: 400px) {
  
}
</style>
