<template>
<div v-if="tableData.length">
  <i>
    正装背光芯片
  </i>
  <el-table stripe :data="tableData" border style=" float: right" :header-cell-style="{background:'#eef1f6',color:'#606266',fontSize: '20'}">
    
    <el-table-column prop="model" label="型号" width="80" align="center"> </el-table-column>
    <el-table-column prop="appearance" label="外观" width="230" align="center">
      <img src="../assets/img/product/fbChip/01.png" alt="" >
          <img src="../assets/img/product/fbChip/02.png" alt="">
          <img src="../assets/img/product/fbChip/03.png" alt="">
          <img src="../assets/img/product/fbChip/04.jpg" alt="">
          
          
      <template slot-scope="scope">
        <img :src="scope.row.appearance" alt=""/>
      </template>
    </el-table-column>
    <el-table-column prop="size" label="尺寸" align="center" width="80"> </el-table-column>
    <el-table-column prop="electric" label="应用电流(mA)" align="center" width="90"> </el-table-column>
    <el-table-column prop="eleSize" label="电极尺寸(μm)" align="center" width="90"> </el-table-column>
    <el-table-column prop="application" label="应用方式" align="center" width="95"> 
      <template slot-scope="scope">
						<span v-html="scope.row.application"></span>
					</template>
    </el-table-column>
    <el-table-column prop="character" label="产品特点" align="center">
					<template slot-scope="scope">
						<span v-html="scope.row.character"></span>
					</template>
				</el-table-column>
  </el-table>
</div>
  
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    async getProduct(){
      let param={page:"1",rows:"120",order:"asc",value:null,wheres:[{name:"Type",value:"fbChip",displayType:"search"}]}
      if (param.wheres ) {
         param.wheres = JSON.stringify(param.wheres);
      }
      await this.http
        .post("/api/ProductInfo/GetPageDataNoPermission",param,false)
        .then((result) => {
          let rows=result.rows
          rows.forEach(x => {
            x.appearance=this.http.ipAddress+x.appearance
          });
          this.tableData = rows.filter(item => item.subType==="fbc")
        });
    }
  },
  mounted() {
    this.getProduct()
  },
};
</script>

<style scoped>
.el-table td, .el-table th {
            text-align: center;
        } 
        .el-table th>.cell{
          font-size: 15px;
        }
        .detail-left{
          height: 0;
        }
       
</style>