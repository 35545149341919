<template>
  <div style="height:100%;">
    <div class="block">
      <div></div>
    </div>
    <div class="result">
      <div class="list-group" v-if="searchData.count!=0">
        <div class="list-group-item" v-for="item in searchData.list" :key="item.id">
          <div>
            <a href="javascript:;" @click="goTo(item.id)">
              <h4 v-html="item.title">{{item.title}}</h4>
            </a>
          </div>
          <p>
            <span class="createTime">{{item.createDate}}</span>&nbsp;&nbsp;
            <span v-html="item.content">{{item.content}}</span>
          </p>
          <a href></a>
        </div>
      </div>
      <div class="no-content" v-if="searchData.count==0">
        很抱歉，没有找到与“
        <span style="color: #c00; font-family: 宋体;">{{keyword}}</span>”相关的内容。
        <div class="tip_head">温馨提示：</div>
        <ul>
          <li>请检查您的输入是否正确</li>
          <li>如内容未收录，请联系管理员</li>
        </ul>
      </div>
    </div>
    <div class="bottom-page">
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="this.pageSize"
          layout="prev, pager, next, jumper"
          :total="this.count"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>


import {searchApi} from "../api/search";

export default {
  name: "result",
  data() {
    return {
      loading: false,
      pdresult: false,
      
      keyword: "",
      pageNo: 1,
      pageSize: 10,
      listItem: {},
      currentPage: 1,
      count: 10,
      searchData:{},
      searchList:[],
    };
  },
  computed: {},
  created() {
    this.keyword = decodeURIComponent(this.$route.query.key);
    
  },
  // created() {
  //   
    
  // },
  watch: {
    $route() {
      if (this.$route.query.key != undefined && this.$route.path == "/result") {
        this.keyword = this.$route.query.key;
        this.getSearch();
      }
      
    }
  },

  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getSearch();
    },
    async getSearch(){
      const result = await searchApi(encodeURIComponent(this.keyword),this.pageNo);
      this.searchData=result;
     this.count=result.count;
    },
    goTo(val) {
      //直接跳转
      this.$router.push({
        path: "/detail",
        query: { id: val },
        
      });
     
    },
    
  },
  mounted() {this.getSearch();}
};
</script>
<style>
html,
body {
  margin: 0;
  height: 100%;
}
body {
  background-color: #f8fafc;
}
.result {
  max-width: 80%;
  min-height: 700px;
  margin: 20px auto;
  
}
.list-group-item {
  /*height: 120px;*/
  /*display:block;*/
  background-color: #fff;
  min-height: 100px;
  padding-left: 5%;
}

.createTime {
  color: #666;
  font-size: 12px;
}
.list-group-item .ng-binding {
  text-decoration: none;
  word-wrap: break-word;
}

.no-content {
  margin-top: 5%;
  font-size: 18px;
  font-family: microsoft yahei;
  color: #000;
}

.no-content .tip_head {
  color: #666;
  font-size: 13px;
  line-height: 28px;
  margin-top: 2%;
}

.no-content li {
  color: #333;
  line-height: 28px;
  font-size: 13px;
  font-family: "宋体";
  /* padding-left: 30px; */
  list-style-position: inside;
  list-style-type: disc;
}
</style>
