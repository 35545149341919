<template>
  <div style>
    <div class="mg">
      <div class="gdh">
        <Header></Header>
      </div>
      <div class="mrHead">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    
      <!-- <div style="height:500px;width:100%;background:#eee;"></div> -->
    </div>
  </div>
</template>



<script>
import Header from "../components/Header2";
// import Footer from "../components/Footer";


export default {
  name: "home",
  components: { Header},
  data() {
    return {};
  }
};
</script>
<style>
/* @media screen and (max-width: 1200px) {
  .mg {
    margin: 0 ;
  }
} */

div .mg .gdh {
  width: 100%;
}
.mrHead {
  margin-top: 104px;
}
.nab {
  position: relative;
  top: 30px;
  padding: 0 30px;
}
.nab .block {
  margin: 10px;
}
.flex {
  display: flex;
}
.flexr {
  display: flex;
  justify-content: flex-end;
}
.ewm {
  width: 120px;
  height: 120px;
}
.cttitle {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}
.gdh {
  position: fixed;
  top: 0;
  z-index: 2000;
  width: calc(100% - 152px);
}
@media (max-width: 1600px) {
  /* .mrHead {
    margin-top: 74px;
  } */
}
@media (max-width: 1500px) {
  
  .gdh {
    position: fixed;
    top: 0;
    z-index: 2000;
    width: calc(100% - 92px);
  }
}
@media (max-width: 1400px) {
  
  .gdh {
    width: calc(100% - 62px);
  }
}
@media (max-width: 1300px) {
  
    .mrHead {
    margin-top: 64px;
  }
  .gdh {
    width: calc(100% - 52px);
  }
}
@media (max-width: 1200px) {
  .mg {
    padding: 0 0;
  }
  .gdh {
    width: 100%;
  }
}
@media (max-width: 1150px) {
   .mrHead {
    margin-top: 64px;
  }
}
/*默认*/
@media (max-width: 980px) {
  .mrHead {
    margin-top: 68px;
  }
}
@media screen and (max-width: 920px) {
  .mrHead {
    margin-top: 60px;
  }
}
/* 平板电脑和小屏电脑之间的分辨率 */
@media (min-width: 768px) and (max-width: 979px) {
}

/* 横向放置的手机和竖向放置的平板之间的分辨率 */
@media (max-width: 767px) {
}

/* 横向放置的手机及分辨率更小的设备 */
@media (max-width: 480px) {
}
</style>
