<template>
  <div class="zp">
    <h3>招聘岗位</h3>
    <h3>更新时间：{{modifyDate}}</h3>
    <div class="offer"  v-if="tableData.length">
      <div class="details" v-for="offer in tableData" :key="offer.offerId">
        <span class="post">{{offer.post}}</span>
        <span class="num">招聘人数：{{offer.number}}人</span>
        <span class="condition">任职条件</span>
        <div v-html="offer.condition"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
      return {
        tableData: [],
        modifyDate:"",
      };
    },
    methods: {
      async getOffer(){
        let param={page:"1",rows:"120",order:"asc",value:null}
        await this.http
          .post("/api/OfferInfo/GetPageDataNoPermission",param,false)
          .then((result) => {
             this.tableData=result.rows
             let maxModifyDate= Math.max.apply(null, this.tableData.map(item => (new Date(item.modifyDate)).valueOf()));
             this.modifyDate=maxModifyDate===0?'2022-4-10':this.$moment(maxModifyDate).format('YYYY-MM-DD');
          });
      }
  },
  mounted() {
    this.getOffer()
  },
  };
</script>

<style scoped>
div.zp {
  /* padding-left: 20px; */
  border: 1px solid #ccc;
}
div.offer {
  padding-left: 20px;
}
div.details {
  margin-bottom: 46px;
}
h3 {
  text-align: center;
  margin-bottom: 66px;
  margin-top: 32px;
  font-size: 26px;
}
li {
  list-style: none;
  margin-top: 3px;
}
span.post,
span.num,
span.condition {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-top: 3px;
}
.details li {
  font-size: 16px;
}
</style>