<template>
  <div>
    <div class="block">
      <div>
        <div class="banner-box">
          <img
            v-if="courseData.images != undefined &&courseData.images != ''"
            :src="jcourseData.images"
            alt
          />
          <img v-else src="../assets/img/img (16).png" alt />
        </div>
      </div>
      <div class="list">
        <div class="list-title">
          <ol class="crumb">
            <li>
              <a href="/">首页</a>
            </li>
            <li>{{ courseData.desc }}</li>
          </ol>
        </div>
        <div class="time-border">
          <h3>兆元历程</h3>
          <ul class="timeline">
            <!-- Item 1 -->
            <li v-for="item in courseList" :key="item.id">
              <div class="direction">
                <div class="flag-wrapper">
                  <span class="hexa"></span>
                  <span class="flag">{{item.title}}</span>
                  <span class="time-wrapper">
                    <span id="setTime" class="time">{{item.courseTime}}</span>
                  </span>
                </div>
                <div class="desc" v-for="citem in item.cmsCourseDataList" :key="citem.id">
                  <div>{{citem.content}}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbar1 from "../assets/img/img1.png";
import navbar2 from "../assets/img/navbar4.jpg";
import navbar3 from "../assets/img/navbar2.jpg";
import navbar4 from "../assets/img/navbar3.jpg";
import { courseApi } from "../api/course";
export default {
  name: "introduction",
  data() {
    return {
      pdresult: false,
      navbar: [navbar1, navbar2, navbar3, navbar4],
      bannerHeight: "",
      screenWidth: "",
      screenHeight: "",
      courseData: {},
      courseList: [],
      cmsCourseDataList: [],
      sId: ""
    };
  },
  created() {
    this.sId = this.$route.query.c;
    this.getCourse();
  },

  watch: {
    $route() {
      if (
        this.$route.query.c != undefined &&
        this.$route.path == "/businessprocess"
      ) {
        this.sId = this.$route.query.c;
        this.getCourse();
      }
    }
  },
  computed: {},
  methods: {
    async getCourse() {
      const result = await courseApi();
      this.courseData = result;
      this.courseList = result.list;
    },
    imgLoad() {
      this.$nextTick(() => {
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      });
    }
  },
  mounted() {
    this.pdresult = window.matchMedia("(min-width: 400px)").matches;

    this.screenWidth = document.body.clientWidth;
    this.bannerHeight = parseInt(this.screenWidth / 3.5);

    this.imgLoad();

    window.addEventListener(
      "resize",
      () => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      },
      false
    );
  }
};
</script>
<style>
* {
  padding: 0;
  margin-left: 0;
}
.flexr {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.title {
  display: none;
}
.banner2 {
  display: none;
}
.time-border {
  width: 100%;
  box-shadow: 0px 1px 1px #ddd;
  border: 1px solid #ddd;
  background-color: #fff;
  height: 100%;
}

.time-border h3 {
  text-align: center;
  font-size: 24px;
  padding-top: 10px;
  font-weight: bold;
  margin: 0;
  padding-bottom: 10px;
}
.direction {
  width: 380px;
}

html,
body {
  width: 100%;
  color: #333;
}

header h1 {
  text-align: center;
  font-weight: bold;
  margin-top: 0;
}

header p {
  text-align: center;
  margin-bottom: 0;
}

.hexa {
  border: 0px;
  float: left;
  text-align: center;
  height: 35px;
  width: 60px;
  font-size: 22px;
  background: #f0f0f0;
  color: #3c3c3c;
  position: relative;
  margin-top: 15px;
}

.hexa:before {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 15px solid #f0f0f0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  top: -15px;
}

.hexa:after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 15px solid #f0f0f0;
  bottom: -15px;
}

.timeline {
  position: relative;
  padding: 0;
  width: 100%;
  min-height: 200px;
  /*margin-top: 20px;*/
  list-style-type: none;
  height: 100%;
}

.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: " ";
  display: block;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background: rgb(213, 213, 213);
  background: -moz-linear-gradient(
    top,
    rgba(213, 213, 213, 0) 0%,
    rgb(213, 213, 213) 8%,
    rgb(213, 213, 213) 92%,
    rgba(213, 213, 213, 0) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(30, 87, 153, 1)),
    color-stop(100%, rgba(125, 185, 232, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(213, 213, 213, 0) 0%,
    rgb(213, 213, 213) 8%,
    rgb(213, 213, 213) 92%,
    rgba(213, 213, 213, 0) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(213, 213, 213, 0) 0%,
    rgb(213, 213, 213) 8%,
    rgb(213, 213, 213) 92%,
    rgba(213, 213, 213, 0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(213, 213, 213, 0) 0%,
    rgb(213, 213, 213) 8%,
    rgb(213, 213, 213) 92%,
    rgba(213, 213, 213, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(213, 213, 213, 0) 0%,
    rgb(213, 213, 213) 8%,
    rgb(213, 213, 213) 92%,
    rgba(213, 213, 213, 0) 100%
  );
  z-index: 5;
}

.timeline li {
  padding: 2em 0;
}

.timeline .hexa {
  width: 16px;
  height: 10px;
  position: absolute;
  background: #00c4f3;
  z-index: 5;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -30px;
  margin-top: 0;
}

.timeline .hexa:before {
  border-bottom: 4px solid #00c4f3;
  border-left-width: 8px;
  border-right-width: 8px;
  top: -4px;
}

.timeline .hexa:after {
  border-left-width: 8px;
  border-right-width: 8px;
  border-top: 4px solid #00c4f3;
  bottom: -4px;
}

.direction {
  float: none;
  width: 100%;
}

.flag-wrapper {
  text-align: center;
  position: relative;
}

.flag {
  position: relative;
  display: inline-block;
  background: rgb(255, 255, 255);
  z-index: 15;
  padding: 6px 10px;
  text-align: left;
  border-radius: 5px;
}

.direction .flag:after,
li:nth-child(2n + 1) .flag:after {
  content: "";
  position: absolute;
  left: 50%;
  top: -15px;
  height: 0;
  width: 0;
  margin-left: -8px;
  border: solid transparent;
  border-bottom-color: rgb(255, 255, 255);
  border-width: 8px;
  pointer-events: none;
}

.direction .flag {
  -webkit-box-shadow: -1px 10px 5px rgba(0, 0, 0, 0.15),
    0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -1px 10px 5px rgba(0, 0, 0, 0.15),
    0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: -1px 10px 5px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  /*background-image: linear-gradient( 135deg, #4C83FF 10%, #2AFADF 100%);*/
  background-image: linear-gradient(135deg, #ee9ae5 10%, #5961f9 100%);
}

li:nth-child(2n + 1) .flag {
  background-image: linear-gradient(135deg, #5efce8 10%, #736efe 100%);
  -webkit-box-shadow: 1px 10px 5px rgba(0, 0, 0, 0.15),
    0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 10px 5px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 10px 5px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

.time-wrapper {
  display: block;
  position: relative;
  margin: 4px 0 0 0;
  z-index: 14;
  line-height: 1em;
  vertical-align: middle;
  color: #fff;
}

/* .direction .time-wrapper {
  float: none;
} */

.time {
  background: #00c4f3;
  display: inline-block;
  padding: 8px;
}

.desc {
  position: relative;
  margin: 1em 0 0 0;
  padding: 1em;
  background: rgb(254, 254, 254);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  z-index: 15;
}

.li:nth-child(2n) .desc {
  box-shadow: -6px 4px 1px rgba(0, 0, 0, 0.15);
}
.li:nth-child(2n + 1) .desc {
  box-shadow: 6px 4px 1px rgba(0, 0, 0, 0.15);
}

.direction .desc {
  background-color: #abdcff;
  font-size: 14px;
  position: relative;
  margin: 1.5em 1em 0 1em;
  padding: 1em;
  border-radius: 10px;
  z-index: 15;
}

@media (min-width: 768px) {
  .timeline {
    width: 660px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .timeline li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .timeline .hexa {
    left: -28px;
    right: auto;
    top: 8px;
  }

  .timeline li:nth-child(2n) .hexa {
    left: auto;
    right: -28px;
  }
  .timeline li {
    display: flex;

    justify-content: flex-end;
  }
  .timeline li:nth-child(2n + 1) {
    margin-right: 70px;
  }
  .timeline li:nth-child(2n) {
    justify-content: flex-start;
    margin-left: 108px;
  }

  .direction {
    position: relative;
    width: 310px;
  }
  .flag-wrapper {
    display: inline-block;
  }

  .flag {
    font-size: 22px;
  }

  li:nth-child(2n) .flag:after {
    left: auto;
    right: -16px;
    margin-top: -8px;
    border: solid transparent;
    border-left-color: rgb(254, 254, 254);
    border-width: 8px;
  }

  li:nth-child(2n + 1) .flag:after {
    top: 50%;
    margin-top: -8px;
    border: solid transparent;
    border-right-color: rgb(254, 254, 254);
    border-width: 8px;
    left: -8px;
  }

  .time-wrapper {
    display: inline;
    vertical-align: middle;
    margin: 0;
  }

  li:nth-child(2n) .time-wrapper {
    float: left;
  }

  li:nth-child(2n + 1) .time-wrapper {
    float: right;
  }

  .time {
    padding: 5px 10px;
  }

  li:nth-child(2n + 1) .desc {
    margin: 1.5em 0 0 0.75em;
  }
}

@media (min-width: 992px) {
  .timeline {
    width: 800px;
    margin: 0 auto;
    /*margin-top: 20px;*/
  }

  .direction {
    position: relative;
    width: 380px;
  }

  .timeline li:nth-child(2n + 1) {
    margin-right: 0;
  }
  .timeline li:nth-child(2n) {
    justify-content: flex-start;
    margin-left: 108px;
  }
  .timeline li:nth-child(2n) .desc {
    text-align: right;
    margin-right: 100px;
  }
  /* 
  .direction:nth-of-type(2) {
    background:cornflowerblue;
    position: relative;
    width: 380px;
    float: right;
    text-align: left;
  } */
}
@media (max-width: 2000px) {
}

@media (max-width: 1300px) {
}
@media (max-width: 980px) {
}
@media (max-width: 860px) {
}
@media (max-width: 768px) {
}

@media (max-width: 560px) {
}
@media (max-width: 480px) {
}
@media (max-width: 400px) {
}
</style>
