<template>
  <div style="height: 100%">
    <div class="block">
      <div>
        <div class="banner-box">
          <p>联系我们</p>
          <img
            v-if="contactData.images != undefined && contactData.images != ''"
            :src="contactData.images"
            alt
          />
          <img v-else src="../assets/img/contact.jpg" alt />
        </div>
      </div>
    </div>
    <div class="list">
      <div class="list-title">
        <ol class="crumb">
          <li>
            <a href="/">首页</a>
          </li>
          <li>{{ contactData.desc }}</li>
        </ol>
      </div>
      <div class="contact-list">
        <div class="contact-address">
          <div class="address-list">
            <h4 class="company">{{ contactData.name }}</h4>
            <p class="address">
              <span>
                <i class="glyphicon glyphicon-map-marker iconColor"></i>
              </span>
              <span>公司地址：</span>
              <span>{{ contactData.address }}</span>
            </p>

            <p class="phone">
              <span>
                <i class="glyphicon glyphicon-earphone iconColor"></i>
              </span>
              <span>电话：</span>
              <span class="number-color">{{ contactData.tel }}</span>
            </p>
            
          </div>
          <div class="contact-map">
            <div id="allmap"></div>
            <p class="address">
              <span>
                <i class="glyphicon glyphicon-map-marker iconColor"></i>
              </span>

              深圳市宝安区永荣路2号万科星宸大厦1栋2508
            </p>
            <div id="newMap"></div>
          </div>
        </div>
        <div class="contact-way">
          <div class="info-list" v-for="item in departmentList" :key="item.id">
            <h4 class="info-title">{{ item.name }}</h4>
            <div class="info-way">
              <div class="username">
                <span>
                  <i class="glyphicon glyphicon-user iconColor"></i>
                </span>
                <span>姓名：</span>
                <span>{{ item.linkUser }}</span>
              </div>
              <div class="usernumber">
                <span>
                  <i class="glyphicon glyphicon-earphone iconColor"></i>
                </span>
                <span>电话：</span>
                <span class="number-color">{{ item.tel }}</span>
              </div>
            </div>
          </div>

          <div class="username email">
            <span>
              <i class="glyphicon glyphicon-envelope iconColor"></i>
            </span>

            <span class="email_t">邮箱：</span>
            <span class="number-color">xiaozhi.wang@primaopto.com</span>
          </div>
        </div>
      </div>
    </div>
    <Footern></Footern>
  </div>
</template>

<script >
import Footern from "../components/FooterNew";

import navbar1 from "../assets/img/img1.png";
import navbar2 from "../assets/img/navbar4.jpg";
import navbar3 from "../assets/img/navbar2.jpg";
import navbar4 from "../assets/img/navbar3.jpg";
import { contactApi, departmentApi } from "../api/contact";
import BMap from "BMap";
import icon from "../assets/img/zuobiao.png";

export default {
  name: "contact",
  components: { Footern },
  data() {
    return {
      pdresult: false,
      navbar: [navbar1, navbar2, navbar3, navbar4],
      bannerHeight: "",
      screenWidth: "",
      screenHeight: "",
      aId: "",
      contactData: {},
      departmentList: [],
    };
  },
  computed: {},
  created() {
    this.aId = this.$route.query.c;
    this.getContact();
    this.getDepartment();
  },

  watch: {
    $route() {
      if (
        this.$route.query.c != undefined &&
        this.$route.path == "/contactUs"
      ) {
        this.aId = this.$route.query.c;
        this.getContact();
        this.getDepartment();
      }
    },
  },

  methods: {
    async getDepartment() {
      const data = await departmentApi();
      this.departmentList = data;
    },

    async getContact() {
      const result = await contactApi();
      this.contactData = result;
    },
    Map() {
      var map = new BMap.Map("allmap");
      const point = new BMap.Point(119.238477,25.9475);
      map.centerAndZoom(point, 16);
      const deviceSize = new BMap.Size(42, 42);
      const deviceIcon = new BMap.Icon(icon, deviceSize, {
        //会以base64的方式传参iconCar
        imageSize: deviceSize,
      });

      const marker = new BMap.Marker(point, {
        icon: deviceIcon,
      });

      map.addOverlay(marker);

      var geoc = new BMap.Geocoder();

      map.addEventListener("click", function (e) {
        var pt = e.point;
        console.log(pt);
        geoc.getLocation(pt, function (rs) {
          var addComp = rs.addressComponents;
          console.log(
            addComp.province +
              ", " +
              addComp.city +
              ", " +
              addComp.district +
              ", " +
              addComp.street +
              ", " +
              addComp.streetNumber
          );
        });
      });

var newMap = new BMap.Map("newMap");
      const newPoint = new BMap.Point(113.839488,22.721298);
      newMap.centerAndZoom(newPoint, 16);

      const marker2 = new BMap.Marker(newPoint, {
        icon: deviceIcon,
      });
      // //把标注添加到地图上
      newMap.addOverlay(marker2);

      var newGeoc = new BMap.Geocoder();

      newMap.addEventListener("click", function (e) {
        var npt = e.newPoint;
        newGeoc.getLocation(npt, function (rs) {
          var newAddComp = rs.addressComponents;
          console.log(
            newAddComp.province +
              ", " +
              newAddComp.city +
              ", " +
              newAddComp.district +
              ", " +
              newAddComp.street +
              ", " +
              newAddComp.streetNumber
          );
        });
      });
    },
    imgLoad() {
      this.$nextTick(() => {
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      });
    },
  },
  mounted() {
    this.pdresult = window.matchMedia("(min-width: 400px)").matches;

    this.screenWidth = document.body.clientWidth;
    this.bannerHeight = parseInt(this.screenWidth / 3.5);

    this.imgLoad();
    this.Map();
    window.addEventListener(
      "resize",
      () => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      },
      false
    );
  },
};
</script>
<style>
html,
* {
  padding: 0;
  margin-left: 0;
}

@font-face {
  font-family: "iconfont";
  src: url("../assets/font/iconfont.eot");
  src: url("../assets/font/iconfont.eot?#iefix") format("embedded-opentype"),
    url("../assets/font/iconfont.woff2") format("woff2"),
    url("../assets/font/iconfont.woff") format("woff"),
    url("../assets/font/iconfont.ttf") format("truetype"),
    url("../assets/font/iconfont.svg#iconfont") format("svg");
}
.info-way1 {
  display: flex;
  justify-content: flex-start;
}

.email {
  position: relative;
  top: -8px;
  left: 0;
}
.email_t {
  color: #999;
}
.usernumber {
  margin-left: 78px;
}
.iconfont {
  font-family: "iconfont" !important;
  /* font-size: 16px; */
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
}
.flexr {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.address, .phone, .email{
  margin-top: -25px;
}
.list {
  width: 80%;
}
.banner2 {
  display: none;
}

.flex {
  display: flex;
  justify-content: space-around;
}
.banner-box {
  position: relative;
}
.banner-box p {
  position: absolute;
  left: 0;
  top: 0;
  color: red;
  font-size: 28px;
  text-shadow: 2px 2px 2px #337ab7;
}
.contact-list {
  width: 100%;
  min-height: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.contact-address {
  padding: 16px;
  width: 48%;
}
.contact-way {
  width: 50%;
  padding: 16px;
}
.company {
  border-left: 4px solid #20b09f;
  padding: 0 8px;
}
span i {
  color: #20b09f;
  margin-right: 5px;
}
.number-color {
  color: #20b09f;
}
.contact-map {
  margin-top: 20px;
}
#allmap,
#newMap {
  width: 100%;
  height: 300px;
  margin-bottom: 33px;
}

#newMap {
  margin-top: 18px;
}
/* .info-list {
  margin-bottom: 24px;
} */


.info-title {
  border-left: 4px solid #20b09f;
  padding: 0 8px;
}

.info-way {
  /* width: 50%; */
  /* justify-content: space-around; */
  display: flex;
  color: #999999;
  margin-top: -25px;
}
@media (max-width: 1500px) {
  .info-way {
    width: 70%;
  }
}
@media (max-width: 1300px) {
  .info-way {
    width: 80%;
  }
}
@media (max-width: 960px) {
  .info-way {
    width: 100%;
  }
}
@media (max-width: 860px) {
}
@media (max-width: 768px) {
  .contact-list {
    flex-direction: column;
  }
  .contact-address {
    width: 100%;
  }
  .contact-way {
    width: 100%;
  }
}

@media (max-width: 560px) {
}
@media (max-width: 480px) {
  .info-way {
    width: 100%;
    flex-direction: column;
    margin-left: 16px;
  }
}
@media (max-width: 400px) {
}
</style>
