<template>
  <div style="height: 100%">
    <div class="block">
      <div>
        <div class="banner-box">
          <p>产品中心</p>
          <img
            v-if="productData.images != undefined && productData.images != ''"
            :src="productData.images"
            alt
          />
          <img v-else src="../assets/img/product.jpg" alt />
        </div>
      </div>
    </div>
    <div class="list">
      <div class="list-title">
        <ol class="crumb">
          <li>
            <a href="/">首页</a>
          </li>

          <li>{{ productData.desc}}</li>
        </ol>
      </div>
      <div class="content-detail">
        <div class="content-list flex">
          <div class="detail-left">
            <div class="recommend">
              <div class="re-title">相关栏目</div>
              <ul class="re-list">
                <router-link to="/productCenter/frontChip" tag="li">正装照明芯片</router-link>
                <router-link to="/productCenter/highChip" tag="li">正装照明高压芯片</router-link>
                <router-link to="/productCenter/fbChip" tag="li">正装背光芯片</router-link>
                <router-link to="/productCenter/flipBackChip" tag="li">倒装背光芯片</router-link>
                <router-link to="/productCenter/flipChip" tag="li">倒装照明芯片</router-link>
                <router-link to="/productCenter/miniChip" tag="li">倒装Mini LED背光芯片</router-link>
                <router-link to="/productCenter/lampChip" tag="li">倒装车灯芯片</router-link>
                <router-link to="/productCenter/dressColoredChip" tag="li">正装有色光芯片</router-link> 
                <router-link to="/productCenter/digitalChip" tag="li">数码芯片</router-link> 
              </ul>
            </div>
          </div>
          <div class="detail-right" style="border-style:none">
            <router-view ></router-view>
          </div>
        </div>
      </div>    
    </div>
    <footern></footern>
  </div>
</template>

<script>
import Footern from "../components/FooterNew";
// import FrontChip from "../components/FrontChip";

import navbar1 from "../assets/img/img1.png";
import navbar2 from "../assets/img/navbar4.jpg";
import navbar3 from "../assets/img/navbar2.jpg";
import navbar4 from "../assets/img/navbar3.jpg";
import { productApi } from "../api/product";
// import FrontChip from '../components/FrontChip.vue';

export default {
  name: "product",
  components: { Footern },
  data() {
    return {
      defaultImg: require("../assets/img/chip.jpg"),
      address: "http://www.primaopto.com",
      pdresult: false,
      navbar: [navbar1, navbar2, navbar3, navbar4],
      bannerHeight: "",
      screenWidth: "",
      screenHeight: "",
      pId: "",
      pageNo: 1,
      pageSize: 10,
      productData: {},
      productList: [],
      currentPage: 1,
      count: 10,
    };
  },
  computed: {},
  created() {
    this.pId = this.$route.query.c;
    this.getProduct();
  },

  watch: {
    $route() {
      if (
        this.$route.query.c != undefined &&
        this.$route.path == "productCenter"
      ) {
        this.pId = this.$route.query.c;
        this.getProduct();
      }
    },
  },

  methods: {
    defaultBackImg() {
      if (event.type == "error") {
        event.target.src = require("../assets/img/chip.jpg");
      }
    },
    async getProduct() {
      const result = await productApi(this.pageNo, this.pageSize);
      this.productData = result;
      this.productList = result.list;
      this.count = result.count;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getJournal();
    },
    imgLoad() {
      this.$nextTick(() => {
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      });
    },
  },
  mounted() {
    this.pdresult = window.matchMedia("(min-width: 400px)").matches;

    this.screenWidth = document.body.clientWidth;
    this.bannerHeight = parseInt(this.screenWidth / 3.5);

    this.imgLoad();

    window.addEventListener(
      "resize",
      () => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
          console.log(this.bannerHeight);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
          console.log(this.bannerHeight);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
          console.log(this.bannerHeight);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
          console.log(this.bannerHeight);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
          console.log(this.bannerHeight);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
          console.log(this.bannerHeight);
        }
      },
      false
    );
  },
};
</script>
<style>
html,
* {
  padding: 0;
  margin-left: 0;
}
html,
body {
  height: 100%;
}
.flexr {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.p78{
  width: 75%;
  float: right;
}
.tac{
  width: 13%;
  float: left;
  /* height: 5000px; */
}
.list {
  width: 80%;
}
.banner-box {
  position: relative;
}
.banner-box p {
  position: absolute;
  left: 0;
  top: 0;
  color: red;
  font-size: 28px;
  text-shadow: 2px 2px 2px #337ab7;
}
.banner2 {
  display: none;
}


.flex {
  display: flex;
  justify-content: space-around;
}
.content-detail {
  width: 100%;
  margin: 10px auto;
  height: 100%;
}
.product-list {
  width: 100%;
  margin: 10px auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}


.product-item {
  width: 33.3%;
  margin: 10px 0;
  padding: 0 10px;
  background: #fff;
}
.product-img {
  margin-top: 10px;
  text-align: center;
  width: 100%;
}
.product-img img {
  width: 100%;
}
.product-caption {
  padding: 9px;
}
.product-caption > h3 {
  font-size: 22px;
  margin: 0;
}
.product-caption > p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 10px 0;
  color: #999;

  font-family: Microsoft YaHei;
}
.list-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  line-height: 36px;
  margin-bottom: 10px;
}
.list-btn a {
  text-decoration: none;
  color: #ffffff;
}
.download {
  width: 47%;
  color: #fff;
  background: #419641;
  text-align: center;
  border-radius: 5px;
}
.preview {
  width: 47%;
  color: #fff;
  background: #5bc0de;
  text-align: center;
  border-radius: 5px;
}
@media (max-width: 980px) {
  .banner-box {
    height: 300px;
  }
}
@media (max-width: 860px) {
  .product-item {
    width: 50%;
    margin: 10px 0;
  }
}
@media (max-width: 768px) {
  .banner-box {
    height: 200px;
  }
}

@media (max-width: 560px) {
}
@media (max-width: 480px) {
  .product-item {
    width: 100%;
  }
}
@media (max-width: 400px) {
}
</style>
