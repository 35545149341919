<template>
  <div class="swiper-container">
   
  </div>
</template>
<script>
import navbar1 from "../assets/img/img1.png";
import navbar2 from "../assets/img/navbar4.jpg";
import navbar3 from "../assets/img/navbar2.jpg";
// import 'swiper/dist/css/swiper.css'

export default {
  name: "Slider",
  data() {
    return {
      navbar: [navbar1, navbar2, navbar3]
    };
  },
  mounted() {
   
  }
};
</script>

<style scoped>
.swiper-container {
  width: 100%;
  margin: 0;
  padding: 0;
}

.swiper-wrapper {
  height: 200px;
}

.swiper-slide img {
  max-width: 100%;
}

.swiper-slide {
  text-align: center;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
</style>