import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import ElementUI from 'element-ui'
import http from './utils/http'
axios.defaults.withCredentials=true;//让ajax携带cookie
// import $ from 'jquery'
// 导入组件相关样式
Vue.prototype.$axios=axios


// import VueCropper from 'vue-cropper'
// Vue.use(VueCropper)
Vue.prototype.http = http;
Vue.prototype.http.init(Vue);
Vue.config.productionTip = false
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css'

// 配置 Vue 插件
Vue.use(ElementUI);

import router from './router'
import store from './store'
import './assets/font/iconfont.css'
import './assets/font/iconfont.js'
import moment from 'moment'//导入文件

Vue.prototype.$moment = moment;

new Vue({
  router,  //子组件可以用this.$router来访问路由
  store,
  render: h => h(App)
}).$mount('#app')
    