<template>
  <div class="wai">
    <h3>兆元之家</h3>
    <div class="first">
      <div class="kk"><span class="title1">员工福利</span></div>

      <div class="welfare">
        <el-row :gutter="10">
          <el-col  :xl="5" :xs="24" :sm="5"
            ><span class="iconfont icon-jiu-butie"></span
            ><span>五险一金</span></el-col
          >
          <el-col  :xl="5" :xs="24" :sm="5"
            ><span class="iconfont icon-jintiebutie"></span
            ><span>年终奖金</span></el-col
          >
          <el-col :xl="5" :xs="24" :sm="5"
            ><span class="iconfont icon-gonghuifuli"> </span
            ><span>工会福利</span></el-col
          >
          <el-col  :xl="5" :xs="24" :sm="5" 
            ><span class="iconfont icon-mianfeitijian"> </span
            ><span>免费体检</span></el-col
          >
          <el-col  :xl="4" :xs="24" :sm="4"
            ><span class="iconfont icon-92"> </span
            ><span>工作餐补贴</span></el-col
          >
        </el-row>

        <el-row :gutter="10">
          <el-col :xl="12" :xs="24" :sm="5" 
            ><span class="iconfont icon-sushe"></span><span>集体宿舍</span>
            <p>
              3-4人间，独立卫生间、空调、洗衣机、热水器、WIFI等设施齐全。
            </p></el-col
          >
          <el-col :xl="12" :xs="24" :sm="5" 
            ><span class="iconfont icon-mianfeibanche"></span
            ><span>免费班车</span>
            <p>两条路线往返市区，另不定时开设购物专车。</p></el-col
          >
          <el-col :xl="12" :xs="24" :sm="5" 
            ><span class="iconfont icon-dianyuanxiujia"></span
            ><span>带薪休假</span>
            <p>员工享有年假、婚假、产假、护理假等法定带薪假期。</p></el-col
          >
          <el-col :xl="12" :xs="24" :sm="5" 
            ><span class="iconfont icon-yundong-"></span
            ><span>娱乐设施齐全</span>
            <p>篮球场、羽毛球场等场地齐全。</p></el-col
          >
        </el-row>
      </div>
    </div>

    <div class="sec">
      <div class="kk"><span class="title1">人才培养</span></div>

      <div class="train">
        <el-carousel type="card" height="300px" arrow="never">
          <el-carousel-item v-for="item in imgData2" :key="item.id">
            <h3 class="small">
              <img :src="item.src" width="433px" height="300px" />
              <!-- <p>{{ item.content }}</p> -->
            </h3>
          </el-carousel-item>
        </el-carousel>

        <!-- <el-carousel :interval="4000" type="card" height="200px">
          <el-carousel-item v-for="item in 6" :key="item">
            <h3 class="medium">{{ item }}</h3>
          </el-carousel-item>
        </el-carousel> -->
      </div>
    </div>
    <div class="third">
      <div class="kk"><span class="title1">员工活动</span></div>

      <div class="train">
        <el-carousel type="card" arrow="never">
          <el-carousel-item
            v-for="item in imgData1"
            :key="item.id"
            height="500px"
          >
            <h3 class="small">
              <img :src="item.src" width="433px" height="300px" />
              <!-- <p>{{ item.content }}</p> -->
            </h3>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgData1: [
        {
          id: 1,

          src: require("../assets/img/family/001.jpg"),
        },
        {
          id: 2,

          src: require("../assets/img/family/007.jpg"),
        },
        {
          id: 3,

          src: require("../assets/img/family/008.jpg"),
        },
        {
          id: 4,

          src: require("../assets/img/family/004.jpg"),
        },
        {
          id: 5,

          src: require("../assets/img/family/006.jpg"),
        },
      ],
      imgData2: [
        {
          id: 2,

          src: require("../assets/img/family/train/002.jpg"),
        },
        {
          id: 3,

          src: require("../assets/img/family/train/003.jpg"),
        },
        {
          id: 4,

          src: require("../assets/img/family/train/007.jpg"),
        },
        {
          id: 5,

          src: require("../assets/img/family/train/005.jpg"),
        },
        {
          id: 6,

          src: require("../assets/img/family/train/006.jpg"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.intro {
  /* font-size: 30px; */
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 30px;
}
h3 {
  text-align: center;
  margin-bottom: 66px;
  margin-top: 32px;
  font-size: 26px;
}
div.kk {
  margin-left: 28px;
  width: 200px;
  padding: 5px 10px;
  /* height: 200px; */
  /* background-color: #337ab7; */
  background-image: url("../assets/img/bg/图片1.png");
  /* background-position: -50px 300px; */
  background-size: 200px 50px;
  background-repeat: no-repeat;
  display: inline-block;
}
div .wai {
  border: 1px solid #ccc;
  /* padding-left: 20px; */
}
h3 p {
  color: #337ab7;
}
.welfare {
  /* padding-left: 10px; */
}
.welfare span {
  text-align: center;
  margin: 20px auto;
  font-size: 18px;
  display: block;
}
.train el-carousel button.el-carousel__arrow {
  height: 0;
  width: 0;
}
.welfare span.iconfont {
  font-size: 40px;
  color: #337ab7;
}
span.title1 {
  display: inline-block;
  font-size: 26px;
  margin-bottom: 30px;
  /* background-image:-webkit-linear-gradient(bottom,#337ab7,rgba(41,144,204,1,));  */
  color: #337ab7;
  margin-left: 38px;
  /* font-weight: bold; */
  /* background-image: url('../assets/img/bg/图片1.png');
  background-position: -50px 300px;
  background-size: 500px 500px; */
}

.el-row {
  margin: 8px 0;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  /* opacity: 0.75; */
  /* line-height: 400px; */
  margin: -35px;
}
@font-face {
  font-family: "iconfont";
  src: url("../assets/font/iconfont.ttf") format("truetype");
}
/* .el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
} */
.iconfont {
  font-family: "iconfont" !important;
  font-size: 20px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.el-carousel__item h3 p {
  font-size: 20px;
  margin-top: 12px;
  /* color:#ccc; */
  /* background-color: red; */
}
.el-col p {
  width: 114px;
  text-align: justify;
  margin: auto;
}

.sec,
.third {
  margin-top: 50px;
  margin-bottom: 50px;
}
.sec .title1,
.third .title1 {
  margin-bottom: 50px;
}
.sec .el-carousel__item,
.third .el-carousel__item {
  text-align: center;
}
</style>