var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('footer',[_c('div',{staticClass:"bottomNav"},[_c('div',{staticClass:"bottN auto"},[_c('dl',{staticClass:"firstdl"},[_c('dt',[_vm._v("走进兆元")]),_c('router-link',{attrs:{"to":{ path: '/showOne', query: { c: 'C8180057037787180685' } }}},[_c('dd',[_vm._v("兆元简介")])]),_c('router-link',{attrs:{"to":{ path: '/showOne', query: { c: 'C8104857750182102378' } }}},[_c('dd',[_vm._v("发展历程")])]),_c('router-link',{attrs:{"to":{ path: '/showOne', query: { c: 'C9097025720051510704' } }}},[_c('dd',[_vm._v("荣誉分享")])])],1),_c('dl',[_c('dt',[_vm._v("新闻中心")]),_c('router-link',{attrs:{"to":{
              path: '/list',
              query: { c: 'C3220321663192048757', n: '新闻中心' },
            }}},[_c('dd',[_vm._v("公司新闻")])]),_c('router-link',{attrs:{"to":{
              path: '/list',
              query: { c: 'C3590197681053740296', n: '环境信息公开' },
            }}},[_c('dd',[_vm._v("环境信息公开")])])],1),_c('dl',[_c('dt',[_vm._v("产品中心")]),_c('router-link',{attrs:{"to":{
              path: '/productCenter/frontChip',
              query: { c: 'C1465594057976738282' },
            }}},[_c('dd',[_vm._v("产品展示")])])],1),_c('dl',[_c('dt',[_vm._v("招标公告")]),_c('router-link',{attrs:{"to":{
              path: '/list',
              query: { c: 'C4619279028124212156', n: '招标公告' },
            }}},[_c('dd',[_vm._v("招标公告")])])],1),_c('dl',[_c('dt',[_vm._v("招贤纳士")]),_c('router-link',{attrs:{"to":{
              path: '/good/family',
              query: { c: 'C9107734384465782046', n: '招贤纳士' },
            }}},[_c('dd',[_vm._v("兆元之家")])]),_c('router-link',{attrs:{"to":{
              path: '/good/offer',
              query: { c: 'C6242724564584350013', n: '招贤纳士' },
            }}},[_c('dd',[_vm._v("招聘岗位")])])],1),_c('dl',[_c('router-link',{attrs:{"to":{ path: '/contactUs', query: { c: 'C2255044775626574933' } }}},[_c('dt',[_vm._v("联系我们")])])],1)]),_vm._m(0)]),_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weixin"},[_c('img',{attrs:{"src":require("../assets/img/erweima.jpg"),"width":"100","height":"100","alt":""}}),_c('div',[_c('span',[_vm._v("关注兆元光电")]),_c('span',{staticClass:"secspan"},[_vm._v("公众号")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottomBox",staticStyle:{"text-align":"center","display":"flex","justify-content":"center"}},[_c('div',{staticClass:"pad_lr"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/"}},[_vm._v(" copyright©福建兆元光电有限公司版权所有")])]),_c('div',{staticClass:"pad_lr"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/"}},[_vm._v(" 备案号：闽ICP备16028789号")])]),_c('div',{staticClass:"pad_lr"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/"}},[_vm._v(" 技术支持：振邦科技")])])])
}]

export { render, staticRenderFns }