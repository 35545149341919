<template>
    <div>
      <i> 倒装车灯芯片 </i>
      <el-table
        :data="tableData"
        border
        style="float: right"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <el-table-column prop="model" label="型号" width="90" align="center">
        </el-table-column>
        <el-table-column
          prop="appearance"
          label="外观"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <img :src="scope.row.appearance" alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="size" label="尺寸" align="center" width="80">
        </el-table-column>
        <el-table-column
          prop="electric"
          label="应用电流(A)"
          align="center"
          width="90"
        >
        </el-table-column>
        <el-table-column
          prop="eleSize"
          label="电极间距(μm)"
          align="center"
          width="90"
        >
        </el-table-column>
        <el-table-column prop="application" label="应用方式" align="center" width="90">
          <template slot-scope="scope">
            <span v-html="scope.row.application"></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="character"
          label="产品特点"
          align="center"
          
        >
          <template slot-scope="scope">
            <span v-html="scope.row.character"></span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        tableData: [],
      };
    },
    methods: {
    async getProduct(){
      let param={page:"1",rows:"120",order:"asc",value:null,wheres:[{name:"Type",value:"lampChip",displayType:"search"}]}
      if (param.wheres ) {
         param.wheres = JSON.stringify(param.wheres);
      }
      await this.http
        .post("/api/ProductInfo/GetPageDataNoPermission",param,false)
        .then((result) => {
          let rows=result.rows
          rows.forEach(x => {
            x.appearance=this.http.ipAddress+x.appearance
          });
          this.tableData = rows.filter(item => item.subType==="fcc")
        });
    }
  },
  mounted() {
    this.getProduct()
  },
  };
  </script>
  
  <style scoped>
  </style>