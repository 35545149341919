<template>
  <div style="height: 100%">
    <div class="block">
      <div>
        <div class="banner-box">
          <p>招贤纳士</p>

          <img src="../assets/img/zoujinzhaoyuan.jpg" alt />
        </div>
      </div>
    </div>
    <div class="list">
      <div class="list-title">
        <ol class="crumb">
          <li>
            <a href="/">首页</a>
          </li>

          <li>{{ intro}}</li>
        </ol>
      </div>
      <div class="content-detail">
        <div class="content-list flex">
          <div class="detail-left">
            <div class="recommend">
              <div class="re-title">相关栏目</div>
              <ul class="re-list">
                <router-link to="/good/family" tag="li" >兆元之家</router-link>
                <router-link to="/good/offer" tag="li" >招聘岗位</router-link>
                <!-- <router-link to="/"  tag="li"><span>兆元之家</span></router-link>
                <router-link to="/showOne?c=C6242724564584350013"  tag="li"><span>招聘岗位</span></router-link> -->
              </ul>
            </div>
          </div>
          <div class="detail-right">
            <router-view ></router-view>
          </div>
        </div>
      </div>    
    </div>

    <!-- <div class="bottom-page">
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="this.pageSize"
          layout="prev, pager, next, jumper"
          :total="this.count"
        ></el-pagination>
      </div>
    </div> -->
    <footern></footern>
  </div>
</template>

<script>
import Footern from "../components/FooterNew";

import navbar1 from "../assets/img/img1.png";
import navbar2 from "../assets/img/navbar4.jpg";
import navbar3 from "../assets/img/navbar2.jpg";
import navbar4 from "../assets/img/navbar3.jpg";
import { articleApi } from "../api/articleList";

export default {
  name: "showOne",
  components: { Footern },

  data() {
    return {
      loading: false,
      pdresult: false,
      navbar: [navbar1, navbar2, navbar3, navbar4],
      bannerHeight: "",
      screenWidth: "",
      screenHeight: "",
      cId: "",
      listItem: {},
      listData: [],
      articleData: {},
      aboutList: [],
      title: "",
      createDate: "",
      user: {},
      intro: "兆元之家",
    };
  },
  computed: {},
  created() {
    this.cId = this.url;
    console.log(this.cId);
    this.getArticle();
    console.log("created", this.cId);
  },

  watch: {
    $route() {
      if(this.$route.path==="/good/family"){
        this.intro = "兆元之家";
      }else{
        this.intro = "招贤纳士";
      }
      if (this.$route.query.c != undefined && this.$route.path == "/good") {
        this.cId = this.$route.query.c;
        console.log('c',this.cId);
        if (
          this.cId == "C8180057037787180685" ||
          this.cId == "C8104857750182102378" ||
          this.cId == "C9097025720051510704"
        ) {
          // console.log(1);
          this.intro = "兆元简介";
        } else if (
          this.cId == "C9107734384465782046" ||
          this.cId == "C6242724564584350013"
        ) {
        
          this.intro = "招贤纳士";
        }
        this.getArticle();
      }
    },
  },

  methods: {
    // async getDetail(){

    //   const result=await detailApi(this.tId,this.title,this.content,this.name);

    // },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    async getArticle() {
      this.loading = true;
      const result = await articleApi(this.pageNo, this.pageSize, this.cId);
      console.log(result);
      this.listItem = result;
      this.listData = result.list;

      // for (var i = 0; i < result.list.length; i++) {
      //   this.articleData = result.list[i].articleData;
      //   this.aboutList = result.list[i].aboutList;
      //   this.title = result.list[i].title;
      //   this.createDate = result.list[i].createDate;
      //   this.user = result.list[i].user;

      //   // for(var j=0;j<this.childlist.length;j++){
      //   //     this.url=this.childlist[j].url;

      //   // }
      // }
    },
    goTo(val) {
      //直接跳转
      this.$router.push({
        path: "/showOne",
        query: { c: val },
      });
    },
    imgLoad() {
      this.$nextTick(() => {
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      });
    },
  },
  mounted() {
    this.pdresult = window.matchMedia("(min-width: 400px)").matches;

    this.screenWidth = document.body.clientWidth;
    this.bannerHeight = parseInt(this.screenWidth / 3.5);

    this.imgLoad();

    window.addEventListener(
      "resize",
      () => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      },
      false
    );
  },
};
</script>
<style>
html,
* {
  padding: 0;
  margin-left: 0;
}
html,
body {
  height: 100%;
}
.flexr {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.el-menu-item {
  font-size: 18px;
  margin: 8px auto;
  text-align: center;
}

.banner2 {
  display: none;
}

.flex {
  display: flex;
  justify-content: space-around;
}
.content-detail {
  width: 100%;
  margin: 10px auto;
  height: 100%;
}
.content-list {
  width: 100%;
  height: 100%;
}

.re-title {
  width: 100%;

  font-size: 18px;
}
.re-list {
  width: 100%;
  height: 100%;
  list-style-type: none;
  margin-bottom: 0;
  text-align: center;
}

.detail-left {
  width: 24%;
  border: 1px solid #ddd;
  height: 100%;
  border-radius: 4px;
}
.detail-right {
  width: 74%;
  border: 1px solid #ddd;
  height: 100%;
  min-height: 700px;
  border-radius: 4px;
}
.right-content {
  width: 100%;
  padding: 30px;
}
.author {
  margin: 24px auto;
  text-align: center;
}

.right-title {
  text-align: center;
}
.right-article {
  margin: 0 auto;
  width: 80%;
}
.right-article img {
  width: 100% !important;
  height: auto !important;
}
@media (max-width: 980px) {
}
@media (max-width: 860px) {
}
@media (max-width: 768px) {
  .content-list .flex {
    flex-direction: column;
  }
  .detail-left {
    width: 100%;
  }
  .detail-right {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 560px) {
}
@media (max-width: 480px) {
}
@media (max-width: 400px) {
}
.recommend > .re-title{
  height: 53px;
  line-height: 53px;
}
</style>
