
<template>
  <div>
    <!-- 搜索 -->

    <nav class="navbar navbar-default titleHeight">
      <div class="container-fluid">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle collapsed"
            style="border: none"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand mrtop1" style="padding: 0" href="/">
            <img style src="../assets/img/logo2.png" alt class="img1" />
          </a>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul class="nav navbar-nav navbar-right mrtop2">
            <li
              v-for="(item, index) in menuData"
              :key="item.id"
              :class="item.childList != undefined ? 'dropdown' : ''"
            >
              <div class="contact-ways" v-if="!item.childList">
                <a href="javascript:;" @click="goTo(item.id, item.href)">{{
                  item.name
                }}</a>
              </div>
              <div v-else>
                <a
                  class="dropdown-toggle hoverCss"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ item.name }}
                  <i
                    v-if="item.childList != undefined"
                    style
                    class="a_icon"
                    :class="
                      activity == index ? 'el-icon-minus' : 'el-icon-plus'
                    "
                  ></i>
                </a>

                <ul class="dropdown-menu" v-if="item.childList">
                  <li
                    v-for="(citem, cindex) in item.childList"
                    :key="cindex"
                    @click="goTo(citem.id, citem.href, item.name)"
                  >
                    <a style="cursor: pointer">{{ citem.name }}</a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.container-fluid -->
    </nav>
  </div>
</template>

<script>
import { categoryApi } from "../api/category";
import { VloginApi, BloginApi, messageApi, exitUserApi } from "../api/login";
import { VregisterApi, SregisterApi } from "../api/register";
// import $ from "jquery";
export default {
  name: "Header",

  data() {
    return {
      address: "http://www.primaopto.com/opto/f",
      dialogSearch: false,
      dialogLogin: false,
      dialogRegist: false,
      isLogin: false,
      loading: false,
      activity: "",
      id: "",
      url: "",
      childlist: [],
      menuData: [],
      keyword: "",
      username: "",
      password: "",

      //会员
      vipform: {
        name: "",
        user: "",
        vpassword: "",
        ypassword: "",
        userIDnumber: "",
        phone: "",
        email: "",
        site: "",
      },

      registerData: {},
      //供应商
      superform: {
        sname: "",
        suserIDnumber: "",
        suser: "",
        spassword: "",
        sypassword: "",
        enterpriseName: "",
        enterpriseType: "",
        enterpriseAddress: "",
        corporateRepresentative: "",
        creditCode: "",
        registeredCapital: "",
        registeredTime: "",
        mainBusiness: "",
        industrialDisputes: "",
        threeYearIncome: "",
        supplyProducts: "",
        businessDirector: "",
        supplyCapacity: "",
        effectivePerformance: "",
        systemCertification: "",
        openAccount: "",
        openHank: "",
        productionEquipment: "",
        testEquipment: "",
        businessContact: "",
        mobilePhone: "",
        fixedTelephone: "",
        semail: "",
        businessScannin: "",
        controlCommit: "",
        systemData: "",
        otherData: "",
      },
      superRegisterData: {},
      userData: {},
      superData: {},
      messageList: {},
      messageData: [],
      rules: {
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        userIDnumber: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "证件号码格式有误！",
            trigger: "blur",
          },
        ],
        vpassword: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 30,
            message: "长度在 6 到 30 个字符",
            trigger: "blur",
          },
        ],
        ypassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
        ],
        user: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[34578]\d{9}$/,
            message: "手机号格式有误",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            type: "email",
            message: "邮箱格式有误",
            trigger: "blur",
          },
        ],
        site: [{ required: true, message: "请输入地址", trigger: "blur" }],
      },
      srules: {
        suser: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        sname: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        suserIDnumber: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "证件号码格式有误！",
            trigger: "blur",
          },
        ],
        spassword: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 30,
            message: "长度在 6 到 30 个字符",
            trigger: "blur",
          },
        ],
        sypassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
        ],
        enterpriseName: [{ required: true, message: "必填", trigger: "blur" }],
        mobilePhone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[34578]\d{9}$/,
            message: "手机号格式有误",
            trigger: "blur",
          },
        ],
        semail: [
          {
            required: true,
            type: "email",
            message: "邮箱格式有误",
            trigger: "blur",
          },
        ],
        enterpriseAddress: [
          { required: true, message: "必填", trigger: "blur" },
        ],
        enterpriseType: [{ required: true, message: "必填", trigger: "blur" }],
        corporateRepresentative: [
          { required: true, message: "必填", trigger: "blur" },
        ],
        creditCode: [{ required: true, message: "必填", trigger: "blur" }],
        registeredCapital: [
          { required: true, message: "必填", trigger: "blur" },
        ],
        registeredTime: [{ required: true, message: "必填", trigger: "blur" }],
        mainBusiness: [{ required: true, message: "必填", trigger: "blur" }],
        industrialDisputes: [
          { required: true, message: "必填", trigger: "blur" },
        ],
        threeYearIncome: [{ required: true, message: "必填", trigger: "blur" }],
        supplyProducts: [{ required: true, message: "必填", trigger: "blur" }],
        effectivePerformance: [
          { required: true, message: "必填", trigger: "blur" },
        ],
        systemCertification: [
          { required: true, message: "必填", trigger: "blur" },
        ],

        openHank: [{ required: true, message: "必填", trigger: "blur" }],
        productionEquipment: [
          { required: true, message: "必填", trigger: "blur" },
        ],
        testEquipment: [{ required: true, message: "必填", trigger: "blur" }],
        businessContact: [{ required: true, message: "必填", trigger: "blur" }],
        fixedTelephone: [{ required: true, message: "必填", trigger: "blur" }],

        businessDirector: [
          { required: true, message: "必填", trigger: "blur" },
        ],
        openAccount: [{ required: true, message: "必填", trigger: "blur" }],
        supplyCapacity: [{ required: true, message: "必填", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getCategory();
  },
  methods: {
    async getCategory() {
      this.loading = true;
      const result = await categoryApi(this.id);
      this.menuData = result;

      for (var i = 0; i < result.length - 1; i++) {
        this.childlist = result[i].childList;

        for (var j = 0; j < this.childlist.length; j++) {
          this.url = this.childlist[j].url;
        }
      }
    },
    //获取会员登录
    async getLogin() {
      const userData = await VloginApi(this.username, this.password);
      this.userData = userData;

      if (this.userData.status == "200" || this.userData.status == "300") {
        this.getMessage();
        this.isLogin = true;
        this.username = "";
        this.password = "";
      } else {
        alert(this.userData.msg);
      }
    },
    //获取会员信息
    async getMessage() {
      const messageList = await messageApi();
      this.messageList = messageList;
      this.messageData = messageList.data;
    },
    // 点击会员登录按钮
    vipLogin() {
      this.dialogLogin = false;
      this.getLogin();
    },
    //获取会员登录
    async getExitUser() {
      const exitData = await exitUserApi();
      console.log(exitData);
    },
    //退出登录
    logout() {
      this.getExitUser();
      this.isLogin = false;
      this.getMessage();
    },
    //获取供应商登录
    async getBusinessLogin() {
      const superData = await BloginApi(this.username, this.password);
      this.superData = superData;
      if (this.superData.status == "200") {
        alert(this.superData.msg);
      } else {
        alert(this.superData.msg);
      }
    },
    //供应商登录
    businessLogin() {
      this.dialogLogin = false;
      this.getBusinessLogin();
      this.username = "";
      this.password = "";
    },
    // 会员注册
    vipRegist(vipform) {
      this.$refs[vipform].validate(async (valid) => {
        if (valid) {
          console.log("验证成功");
          const registerData = await VregisterApi(this.vipform);
          this.registerData = registerData;
          if (registerData.status == 200) {
            this.$message({
              message: registerData.msg,
              type: "success",
            });
          } else {
            this.$message.error(registerData.msg);
          }
          this.dialogRegist = false;
          this.vipform.name = "";
          this.vipform.user = "";
          this.vipform.vpassword = "";
          this.vipform.ypassword = "";
          this.vipform.userIDnumber = "";
          this.vipform.phone = "";
          this.vipform.email = "";
          this.vipform.site = "";
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //清空注册会员信息
    clearAll() {
      this.vipform = {};
    },
    //清空注册供应商信息
    clearSuper() {
      this.superform = {};
    },
    //供应商注册
    superRegister(superform) {
      this.$refs[superform].validate(async (valid) => {
        if (valid) {
          const superRegisterData = await SregisterApi(this.superform);
          this.superRegisterData = superRegisterData;
          if (superRegisterData.status == 200) {
            this.$message({
              message: superRegisterData.msg,
              type: "success",
            });
            this.$refs.upload.submit();
          } else {
            this.$message.error(superRegisterData.msg);
          }
          this.dialogRegist = false;
        } else {
          return false;
        }
      });
    },
    goSearch() {
      this.dialogSearch = true;
    },
    //提交搜索信息
    submit(val) {
      this.dialogSearch = false;
      this.$router.push({
        path: "/result",
        query: { key: val },
      });
    },

    changeActivity(data) {
      if (this.activity == data) {
        this.activity = "";
      } else {
        this.activity = data;
      }
    },
    goTo(val, url, pname) {
      this.$router.push({
        path: url,
        query: { c: val, n: pname },
        
      });
      console.log(url)
    },

    // goTo(val, url) {
    //   var n = url.slice(14, 18);

    //   if (n == "list") {
    //     //直接跳转
    //     this.$router.push({
    //       path: "/list",

    //       query: { c: val }
    //     });
    //     console.log('跳转了',val)
    //   } else if (n == "show") {
    //     this.$router.push({
    //       path: "/showOne",
    //       query: { c: val }
    //     });
    //   } else if (n == "prod") {
    //     this.$router.push({
    //       path: "/productCenter",
    //       query: { c: val }
    //     });
    //   } else if (n == "busi") {
    //     this.$router.push({
    //       path: "/businessprocess",
    //       query: { c: val }
    //     });
    //   } else if (n == "mage") {
    //     this.$router.push({
    //       path: "/magezine",
    //       query: { c: val }
    //     });
    //   } else if (n == "cont") {
    //     this.$router.push({
    //       path: "/contactUs",
    //       query: { c: val }
    //     });
    //   }
    // }
  },
};
</script>
<style>
input[type="file"] {
  display: none;
}
.nav > li > div > a {
  font-size: 18px;
  margin-left: 18px;
}
#common .el-form-item__error {
  left: 264px;
}
.el-form-item__content {
  line-height: 20px;
}
#myTabContent {
  margin-top: 20px;
}
.navbar-right > .btn {
  width: 81px;
  height: 32px;
  font-family: "Microsoft YaHei";
  font-size: 14px;

  color: #ffffff;
  background: url(../assets/img/searchBg.png);
  background-size: 100% 100%;
}
.btn > span {
  color: #fff;
}
.icon {
  display: inline-block;
  width: 21px;
  height: 21px;
  vertical-align: middle;
}

.a_icon {
  display: none;
}
.img1 {
  height: 60px;
}
/* .none1 {
  margin-right: 2px;
} */
.none1 > a > span {
  color: #2e2e2e;
}
.none2 {
  display: none !important;
}
.titleHeight {
  border-radius: 0px;
  height: 104px;
  border: 0px;
  background: white;
}
.mrtop1 {
  margin-top: 20px;
}
.mrtop2 {
  margin-top: 72px;
}
.mrtop3 {
  margin-top: 50px;
  height: 50px;
  line-height: 50px;
}
.mrLeft {
  margin-left: 10px;
}
.nav > li > div > a {
  padding: 10px 15px;
  text-decoration: none;
}
.navbar-default .navbar-nav > li > div > a:hover {
  color: #fff;
  border-bottom: 3px solid #008aff;
  background-color: #008aff;
}
/* .navbar-default .navbar-nav > li>div>a:focus{
  background-color: #008aff;
  border-bottom: 3px solid #008aff;
  color:#fff;
} */
/* .navbar-default .navbar-nav > li>div>  a:active{
  background-color: #008aff;
  border-bottom: 3px solid #008aff;
  color:#fff;
} */
.container-fluid {
  padding-right: 0px;
}
.navbar-default .navbar-nav > li a {
  color: #000;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background: #008aff;
  color: white;
  opacity: 0.6;
}

.collapsing {
  overflow-x: hidden;
  background: #008aff;
  color: white;
}
.titleHeight {
  padding: 0 80px;
}
.dropdown-menu {
  padding: 0px;
  margin: 0px;
}

.navbar-toggle {
  margin-top: 16px;
  margin-right: -49px;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: #5ebde3;
  color: white;
  border-bottom: 3px solid #008aff;
}
@media (max-width: 1600px) {
  .nav > a {
    padding: 0 14px;
  }
}
@media (max-width: 1400px) {
}
@media (max-width: 1300px) {
  .img1 {
    height: 50px;
  }
  .titleHeight {
    height: 84px;
  }
  .mrtop1 {
    margin-top: 15px;
    margin-left: -72px;
  }
  .mrtop2 {
    margin-top: 45px;
  }
  .mrtop3 {
    margin-top: 30px;
  }
  .nav {
    padding-left: 20px;
  }
  .nav > a {
    padding: 0 14px;
  }
}

@media (max-width: 1150px) {
  .img1 {
    height: 40px;
  }
  .titleHeight {
    height: 64px;
  }
  .nav > li > div > a {
    padding: 4px 10px;
    font-size: 22px;
  }
  .mrtop2 {
    margin-top: 34px;
  }
  .mrtop3 {
    margin-top: 26px;

    height: 40px;
    line-height: 40px;
  }
  .nav {
    padding-left: 10px;
  }
  .nav > a {
    padding: 0 8px;
  }
  .navbar-right ~ .navbar-right {
    margin-right: 130px;
  }
}

/*默认*/
@media (max-width: 980px) {
  .navbar-collapse {
    padding-right: 0px;
  }
  .nav > li > a {
    padding: 4px 5px;
  }
  .none1 {
    display: none !important;
  }

  .nav > a {
    padding: 0 6px;
  }
  .mrtop2 {
    margin-top: 26px;
  }
}
@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: -16px;
    top: 30px;
    text-align: center;
    width: 140px;
  }

  .navbar-right .dropdown-menu li > a {
    padding: 8px 0;
  }
  /* .nav li:hover ul{
  display: block;
} */
  .nav li:hover ul {
    display: block;

    border-top: 6px solid #008aff;
  }
}
@media (max-width: 768px) {
  .contact-ways {
    padding-top: 14px;
    border-top: 1px solid #5ebde3;
  }
}

/* 平板电脑和小屏电脑之间的分辨率 */
@media (min-width: 768px) and (max-width: 979px) {
}

/* 横向放置的手机和竖向放置的平板之间的分辨率 */
@media (max-width: 767px) {
  .a_icon {
    /* margin-left: 90%;
    margin-top: 16px; */
    /* margin-left: 85%; */
    display: inline;
  }
  .none1 {
    display: block !important;
    margin: 0 -15px;
    padding: 0;

    text-align: center;
    background: #fff;
  }
  .none1 > a {
    margin: 0 30px;
  }
  .none1 > a > span {
    color: rgb(58, 97, 22);
    font-weight: bold;
  }
  .mrtop2 {
    margin-top: 0px;
  }
  .hoverCss {
    display: block;
    height: 40px;
    line-height: 32px !important;
    border-top: 1px solid #5ebde3;
  }
  .navbar-default .navbar-nav > li > a:hover {
    border-top: 1px solid #5ebde3;
  }

  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus {
    color: white;
  }
  .navbar-collapse.in {
    overflow-x: hidden;
    background: #4497df;

    color: white;
  }
  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:hover,
  .navbar-default .navbar-nav > .open > a:focus {
    background-color: #008aff;
    border-top: 1px solid #5ebde3;
    color: white;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: white;
  }
  .navbar-default .navbar-nav > li a {
    color: white;
  }
  .navbar-default .navbar-nav > li > a:hover {
    color: white;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #d6d7d8;
  }
  /* .navbar-default .navbar-nav > li > a:hover {
    border-bottom: 0px;
  } */
}

/* 横向放置的手机及分辨率更小的设备 */
@media (max-width: 480px) {
}
</style>