<template>
  <div class>
    <footer>
      <div class="bottomNav">
        <div class="bottN auto">
          <dl class="firstdl">
            <dt>走进兆元</dt>
            <router-link
              :to="{ path: '/showOne', query: { c: 'C8180057037787180685' } }"
            >
              <dd>兆元简介</dd>
            </router-link>
            <router-link
              :to="{ path: '/showOne', query: { c: 'C8104857750182102378' } }"
            >
              <dd>发展历程</dd>
            </router-link>
            <router-link
              :to="{ path: '/showOne', query: { c: 'C9097025720051510704' } }"
            >
              <dd>荣誉分享</dd>
            </router-link>
          </dl>
          <dl>
            <dt>新闻中心</dt>
            <router-link
              :to="{
                path: '/list',
                query: { c: 'C3220321663192048757', n: '新闻中心' },
              }"
            >
              <dd>公司新闻</dd>
            </router-link>
            <router-link
              :to="{
                path: '/list',
                query: { c: 'C3590197681053740296', n: '行业信息公开' },
              }"
            >
              <dd>环境信息公开</dd>
            </router-link>
          </dl>
          <dl>
            <dt>产品中心</dt>
            <router-link
              :to="{
                path: '/productCenter/frontChip',
              }"
            >
              <dd>产品展示</dd>
            </router-link>
          </dl>
          <dl>
            <dt>招标公告</dt>
            <router-link
              :to="{
                path: '/list',
                query: { c: 'C4619279028124212156', n: '招标公告' },
              }"
            >
              <dd>招标公告</dd>
            </router-link>
          </dl>
          <dl>
            <dt>招贤纳士</dt>
            <router-link
              :to="{
                path: '/list',
                query: { c: 'C9107734384465782046', n: '招贤纳士' },
              }"
            >
              <dd>兆元之家</dd>
            </router-link>
            <router-link
              :to="{
                path: '/list',
                query: { c: 'C6242724564584350013', n: '招贤纳士' },
              }"
            >
              <dd>招聘岗位</dd>
            </router-link>
          </dl>
          <dl>
            <router-link
              :to="{ path: '/contactUs', query: { c: 'C2255044775626574933' } }"
            >
              <dt>联系我们</dt>
            </router-link>
          </dl>
        </div>
        <div class="weixin">
          <img
            src="../assets/img/erweima.jpg"
            width="100"
            height="100"
            alt=""
          />
          <div>
            <span>关注兆元光电</span>
            <span class="secspan">公众号</span>
          </div>
        </div>
      </div>
      <div
        class="bottomBox"
        style="text-align: center; display: flex; justify-content: center"
      >
        <div class="pad_lr">
          <a href="https://beian.miit.gov.cn/"> copyright&copy;福建兆元光电有限公司版权所有</a>
        </div>
        <div class="pad_lr"><a href="https://beian.miit.gov.cn/"> 备案号：闽ICP备16028789号</a></div>
        <div class="pad_lr"><a href="https://beian.miit.gov.cn/"> 技术支持：振邦科技</a></div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  watch: {
    $route() {
      if (this.$route.query.c != undefined) {
        this.cId = this.$route.query.c;
        // console.log(this.cId)
      }
    },
  },
};
</script>
<style scoped>
a{
  text-decoration: none;
  color: #fff;
}
.bottomBox {
  background: #000;
  width: 100%;
  color: #ffffff;
  font-size: 12px;
  font-family: "Microsoft YaHei";
  flex-wrap: wrap;
}
.weixin span {
  font-size: 14px;
  display: block;
  color: #777;
  padding: 0 5px;
}
.secspan {
  margin-left: 22px;
}
.bottomNav .bottN .auto {
  width: 1200px;
  margin: 0 auto;
  margin-right: 40px;
}
.bottomNav dl {
  margin-top: 25px;
  float: left;
  margin-left: 87px;
}
.bottomNav dl a {
  text-decoration: none;
}
.bottomNav dd:hover {
  color: #337ab7;
}
.bottomNav .firstdl {
  margin-left: 0px;
}
.bottomNav dl dt {
  font-size: 18px;
  color: #666;
}
div .bottomNav {
  /* float: left; */
  width: 75%;
  /* padding: 0 270px; */
  margin: 0 auto;
  /* width: 100%; */
  background: #fff;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 35px;
}

.bottomNav img {
  margin-top: 15px;
  /* margin-right: 5px; */
}
.bottomNav dl dd {
  margin-top: 15px;
  font-size: 16px;
  color: #777;
  cursor: pointer;
  margin-left: 3px;
}
@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .bottomNav dl {
    margin-left: 70px;
  }
  div .bottomNav {
    width: 70%;
  }
}
@media screen and (min-width: 1500px) and (max-width: 1580px) {
  .bottomNav dl {
    margin-left: 70px;
  }
  div .bottomNav {
    width: 70%;
  }
}
@media screen and (min-width: 1580px) and (max-width: 1680px) {
  .bottomNav dl {
    margin-left: 104px;
  }
  div .bottomNav {
    width: 70%;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1800px) {
  .bottomNav dl {
    margin-left: 112px;
  }
  div .bottomNav {
    width: 70%;
  }
}
@media screen and (min-width: 1800px) and (max-width: 2000px) {
  .bottomNav dl {
    margin-left: 132px;
  }
  div .bottomNav {
    width: 70%;
  }
}
@media screen and (min-width: 2000px) and (max-width: 2150px) {
  .bottomNav dl {
    margin-left: 148px;
  }
  div .bottomNav {
    width: 70%;
  }
}
@media screen and (min-width: 2150px) {
  .bottomNav dl {
    margin-left: 120px;
  }
  div .bottomNav {
    width: 70%;
  }
}
.pad_lr {
  padding: 0 5px;
}
/* .bottomNav dl {
  margin-top: 25px;
  float: left;
  margin-left: 28px;
} */
@media (max-width: 480px) {
  flexr {
    padding: 0;
  }
}
</style>
