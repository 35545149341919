import request from '@/utils/request';

export async function VloginApi(username, password) {
  const response = await request.post('/f/member/memberLogin', null, {
    params: {
      userName: username,
      password
    }
  });
  return response;
}
export async function BloginApi(username, password) {
  const response = await request.post('/f/member/supLogin', null, {
    params: {
      userName: username,
      password
    }
  });
  return response;
}
export async function messageApi() {
  const response = await request.post('/f/member/findMessage', null, {
    params: {

    }
  });
  return response;
}
export async function exitUserApi() {
  const response = await request.post('/f/member/exitUser', null, {
    params: {

    }
  });
  return response;
}