<template class="clearfix">
  <div>
    
    <div class="clearfix" v-if="tableData.length">
      <i>正装照明芯片--小尺寸照明</i>
      <el-table
       :header-cell-style="{background:'#eef1f6',color:'#606266',fontSize: 22}"
        :data="tableData"
        border
        stripe
        class="clearfix"
        style=" float: right;"
      
      >
        <el-table-column prop="model" label="型号"   align="center" width="90">
        </el-table-column>
        <el-table-column
          prop="appearance"
          label="外观"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <img :src="scope.row.appearance" alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="size" label="尺寸" align="center" width="80">
        </el-table-column>
        <el-table-column prop="electric" label="应用电流(mA)" align="center" width="90">
        </el-table-column>
        <el-table-column prop="eleSize" label="电极尺寸(μm)" align="center" width="90">
        </el-table-column>
        <el-table-column prop="application" label="应用方式" align="center" width="90">
          <template slot-scope="scope">
						<span v-html="scope.row.application"></span>
					</template>
        </el-table-column>
        <el-table-column prop="flux" label="典型光通量2835(Ra80,5700K)" align="center" width="150">
          
        </el-table-column>
        <el-table-column prop="character" label="产品特点" align="center">
          <template slot-scope="scope">
            <span v-html="scope.row.character"></span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="ser clearfix" v-if="tableData1.length">
     <i>正装照明芯片--中尺寸照明</i>
      <el-table
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
        :data="tableData1"
        border
        style=" float: right;"
      >
        <el-table-column prop="model" label="型号" align="center" width="90">
        </el-table-column>
        <el-table-column
          prop="appearance"
          label="外观"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <img :src="scope.row.appearance" alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="size" label="尺寸" align="center" width="80">
        </el-table-column>
        <el-table-column prop="electric" label="应用电流(mA)" align="center" width="90">
        </el-table-column>
        <el-table-column prop="eleSize" label="电极尺寸(μm)" align="center" width="90">
        </el-table-column>
        <el-table-column prop="application" label="应用方式" align="center" width="90">
          <template slot-scope="scope">
						<span v-html="scope.row.application"></span>
					</template>
        </el-table-column>
        <el-table-column prop="flux" label="典型光通量2835(Ra80,5700K)" align="center" width="150">
          
        </el-table-column>
        <el-table-column prop="character" label="产品特点" align="center">
          <template slot-scope="scope">
            <span v-html="scope.row.character"></span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="ser clearfix" v-if="tableData2.length">
    <i class="intro">正装照明芯片--中大尺寸照明</i>
      <el-table
      :header-cell-style="{background:'#eef1f6',color:'#606266'}"
        :data="tableData2"
        border
        style="float: right;"
      >
        <el-table-column prop="model" label="型号" align="center" width="90">
        </el-table-column>
        <el-table-column
          prop="appearance"
          label="外观"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <img :src="scope.row.appearance" alt=""/>
          </template>
        </el-table-column>
        <el-table-column prop="size" label="尺寸" align="center" width="80">
        </el-table-column>
        <el-table-column prop="electric" label="应用电流(mA)" align="center" width="90">
        </el-table-column>
        <el-table-column prop="eleSize" label="电极尺寸(μm)" align="center" width="90">
        </el-table-column>
        <el-table-column prop="application" label="应用方式" align="center" width="90">
          <template slot-scope="scope">
						<span v-html="scope.row.application"></span>
					</template>
        </el-table-column>
        <el-table-column prop="flux" label="典型光通量2835(Ra80,5700K)" align="center" width="150">
          
        </el-table-column>
        <el-table-column prop="character" label="产品特点" align="center">
          <template slot-scope="scope">
            <span v-html="scope.row.character"></span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
  import {productApi} from "../api/product.js";
export default {
  data() {
    return {
      tableData:[],
      tableData1:[],
      tableData2:[],
    };
  },
  methods: {
    async getProduct(){
      let param={page:"1",rows:"120",order:"asc",value:null,wheres:[{name:"Type",value:"frontChip",displayType:"search"}]}
      if (param.wheres ) {
         param.wheres = JSON.stringify(param.wheres);
      }
      await this.http
        .post("/api/ProductInfo/GetPageDataNoPermission",param,false)
        .then((result) => {
          let rows=result.rows
          rows.forEach(x => {
            x.appearance=this.http.ipAddress+x.appearance
          });
          this.tableData = rows.filter(item => item.subType==="ssl")
          this.tableData1 = rows.filter(item => item.subType==="msl")
          this.tableData2 = rows.filter(item => item.subType==="xsl")

        });
    }
  },
  mounted() {
    this.getProduct()
  },
};
</script>

<style>
i{
  font-style: normal;
  font-size: 24px;
  text-align: center;
  margin: 30px auto;
  display: block;
}

.clearfix:after{
        content: "";
        display: block;
        height: 0;
        clear:both;
        visibility: hidden;
}
</style>