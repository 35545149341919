import request from '@/utils/request';

export async function VregisterApi(vipform) {
  const response = await request.post('/f/member/memberRegister', null, {
    params: {
      realName: vipform.name,
      userName: vipform.user,
      password: vipform.ypassword,

      idNumber: vipform.userIDnumber,
      phoneNumber: vipform.phone,
      email: vipform.email,
      address: vipform.site
    }
  });
  return response;
}
export async function SregisterApi(superform) {
  const response = await request.post('/f/member/cmsRegister', null, {
    params: {
      userName: superform.suser,
      password: superform.spassword,
      realName:superform.sname,
      suserIDnumber:superform.suserIDnumber,
      enterpriseName: superform.enterpriseName,
      enterpriseType: superform.enterpriseType,
      enterpriseAddress: superform.enterpriseAddress,
      corporateRepresentative: superform.corporateRepresentative,
      creditCode: superform.creditCode,
      registeredCapital: superform.registeredCapital,
      registeredTime: superform.registeredTime,
      mainBusiness: superform.mainBusiness,
      industrialDisputes: superform.industrialDisputes,
      threeYearIncome: superform.threeYearIncome,
      supplyProducts: superform.supplyProducts,
      businessDirector: superform.businessDirector,
      supplyCapacity: superform.supplyCapacity,
      effectivePerformance: superform.effectivePerformance,
      systemCertification: superform.systemCertification,
      openAccount: superform.openAccount,
      openHank: superform.openHank,
      productionEquipment: superform.productionEquipment,
      testEquipment: superform.testEquipment,
      businessContact: superform.businessContact,
      mobilePhone: superform.mobilePhone,
      fixedTelephone: superform.fixedTelephone,
      semail: superform.semail,
      businessScannin: superform.businessScannin,
      controlCommit: superform.controlCommit,
      systemData: superform.systemData,
      otherData: superform.otherData
    }
  });
  return response;
}