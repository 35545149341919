<template>
  <div class="main">
    <el-backtop></el-backtop>

    <div class="bg"></div>
    <div class="block">
      <div class="auto">
        <!-- <video
          muted
          id="startvideo"
          class="auto clearfix"
          poster="../assets/img/vedio.png"
          autoplay
          loop
        >
          <source src="../assets/video/01.mp4" type="video/mp4" />
        </video> -->
        <video width="100%" autoplay loop muted>
          <source src="../assets/video/01.mp4" type="video/mp4" />
          <source src="../assets/video/01.mp4" type="video/ogg" />
          <source src="../assets/video/01.mp4" type="video/webm" />
          <object data="../assets/video/01.mp4" width="320" height="240">
            <embed src="../assets/video/01.mp4" width="320" height="240" />
          </object>
        </video>

        <!-- <img src="../assets/img/img1.png" width="100%" height="400" alt=""> -->
        <!-- <embed src="../assets/video/01.mp4" type="" width="100%"> -->
      </div>
      <div class="content1">
        <div
          style="height: 3px; width: 100%; background: #d2d2d2; margin-top: 8px"
        ></div>
        <div class="intro_honor">
          <el-row :gutter="100">
            <el-col :span="12" :xs="24">
              <div class="intro_text clearfix">
                <h3>兆元简介</h3>
                <p>
                  福建兆元光电有限公司（以下简称“兆元光电”）成立于2011年3月，注册资本14.37亿元，是中国电子百强企业福建省电子信息集团控股企业，为福建省重点企业及国家高新技术企业。公司位于福州市闽侯县南屿镇生物医药与机电产业园，占地253亩，建筑面积13万平方米，主要从事LED外延片、LED芯片的研发、生产和销售业务。<br />
                </p>
                <p class="secp">
                  兆元光电LED产业基地建设项目是福建省重点项目、福州市重点项目，总投资31.77亿元，分两期进行建设。项目一期总投资10亿元，建成产能930片外延片/RUN(所有MOCVD机台同时开启）生产线和10万片/月芯片生产线；项目二期总投资21.77亿元，建成100万片/月外延片及68万片/月芯片生产线。2022年，公司上马了填平补齐技改项目，通过优化产线布局，在现有厂房区域新增芯片制造瓶颈设备，增加芯片产能32万片/月。
                </p>
              </div>
            </el-col>
            <el-col :span="12" :xs="24">
              <div style="margin-top: 50px;">
                <el-carousel :interval="8000">
                  <el-carousel-item v-for="item in imgData" :key="item.id">
                    <h3 class="small">
                      <img :src="item.src" width="433px" height="300px" />
                    </h3>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-col>
            
          </el-row>
        </div>
        <div class="move_author">
          <div class="move clearfix">
            <h3>兆元动态</h3>
            <div
              class="list-content"
              v-for="citem in listItem.list"
              :key="citem.cId"
            >
              <div class="list-item">
                <div class="item-con">
                  <div class="item-title" @click="goTo(citem.id)">
                    <a>{{ citem.title }}</a>
                    <span class="list-time">{{ citem.createDate }}</span>
                  </div>
                  <div class="content" style="color: #696969">
                    {{ citem.description }}
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="author clearfix">
            <el-carousel height="320px" :interval="8000">
              <el-carousel-item v-for="item in imgData1" :key="item.id">
                <h3 class="small">
                  <img :src="item.src" width="433px" height="300px" />
                </h3>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <div class="pro clearfix">
          <h3>产品展示</h3>
          <div class="newPro">
            <el-row class="firrow">
              <el-col class="fircol" :span="11">
                <router-link to="/productCenter/frontChip"
                  ><span>正装照明芯片</span></router-link
                >
              </el-col>
              <el-col class="seccol" :span="11">
                <router-link to="/productCenter/flipChip"
                  ><span>倒装照明芯片</span></router-link
                ></el-col
              >
              <el-col class="thircol" :span="7">
                <router-link to="/productCenter/highChip"
                  ><span>正装照明高压芯片</span></router-link
                ></el-col
              >
            </el-row>
            <el-row class="secrow">
             
              <el-col class="foucol" :span="7">
                <router-link to="/productCenter/fbChip"
                  ><span>正装背光芯片</span></router-link
                ></el-col
              >
              <el-col class="fifthcol" :span="7">
                <router-link to="/productCenter/miniChip"
                  ><span>倒装Mini LED背光芯片</span></router-link
                ></el-col
              >
              <el-col class="sixcol" :span="7">
                <router-link to="/productCenter/lampChip"
                  ><span>倒装车灯芯片</span></router-link
                ></el-col
              >
              <el-col class="sevencol" :span="7">
                <router-link to="/productCenter/flipBackChip"
                  ><span>倒装背光芯片</span></router-link
                ></el-col
              >
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../components/Footer";
import navbar1 from "../assets/img/img1.png";
import navbar2 from "../assets/img/navbar4.jpg";
import navbar3 from "../assets/img/navbar2.jpg";
import { indexListApi } from "../api/indexList";
import { articleApi } from "../api/articleList";
import { bannerApi } from "../api/banner";
export default {
  name: "First",
  components: { Footer },
  data() {
    return {
      pdresult: false,
      id: "C3220321663192048757",
      navbar: [navbar1, navbar2, navbar3],
      bannerHeight: "",
      screenWidth: "",
      screenHeight: "",
      cId: "",
      pageNo: 1,
      pageSize: 5,
      listItem: {},
      currentPage: 1,
      count: 10,
      imgData: [
        { id: 2, src: require("../assets/img/banner5.jpg") },
        { id: 3, src: require("../assets/img/honor/01.jpg") },
        { id: 4, src: require("../assets/img/honor/02.jpg") },
        { id: 5, src: require("../assets/img/honor/03.jpg") },
        { id: 6, src: require("../assets/img/honor/04.jpg") },
      ],
      imgData1: [
        { id: 0, src: require("../assets/img/big01.jpg") },
        { id: 1, src: require("../assets/img/big02.jpg") },
        { id: 2, src: require("../assets/img/big03.jpg") },
        { id: 3, src: require("../assets/img/big04.jpg") },
        { id: 4, src: require("../assets/img/big05.jpg") },
      ],
      errorImg: 'this.src="' + require("../assets/img/img1.png") + '"',
      indexList: [],
      indexNews: [],
      bannerList: [],
    };
  },
  computed: {},
  created() {
    this.cId = this.$route.query.c;
    this.getArticle();
    this.load();
    this.getArticleList();
    this.getBanner();
    this.getImgs();
  },
  watch: {
    $route() {
      if (this.$route.query.c != undefined && this.$route.path == "/list") {
        this.cId = this.$route.query.c;
        this.getArticle();
      }
    },
  },
  methods: {
    async getImgs(){

    },
    async getArticle() {
      this.loading = true;
      const result = await articleApi(this.pageNo, this.pageSize, this.cId);
      this.listItem = result;
      this.count = result.count;
    },
    async load() {
      const result = await indexListApi();
      this.indexList = result;
    },
    async getArticleList() {
      const data = await articleApi(this.pageNo, this.pageSize, this.id);
      this.listItem = data;
      this.indexNews = data.list;
    },
    async getBanner() {
      const bannerList = await bannerApi();
      this.bannerList = bannerList;
    },
    goTo(val) {
      //直接跳转
      this.$router.push({
        path: "/detail",
        query: { id: val },
        // name: "detail",
        // params: { id: val }
      });
    },
    imgLoad() {
      this.$nextTick(() => {
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      });
    },
  },
  mounted() {
    this.pdresult = window.matchMedia("(min-width: 400px)").matches;

    this.screenWidth = document.body.clientWidth;
    this.bannerHeight = parseInt(this.screenWidth / 3.5);

    this.imgLoad();

    window.addEventListener(
      "resize",
      () => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      },
      false
    );
  },
};
</script>
<style scoped>
.el-backtop {
  font-size: 35px;
  width: 45px;
  height: 45px;
  margin-bottom: 50px;
  margin-right: -20px;
  color: #fff;
  background-color: #000;
}
h3 {
  font-weight: bold;
  font-size: 32px;
}
.auto {
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 0 auto;
}
.main {
  position: relative;
}

body,
ul,
li,
h1,
h2,
h4,
h5,
h6,
p,
form,
dl,
dt,
dd {
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  font-weight: normal;
}
ul {
  list-style: none;
}
img {
  border-style: none;
}
a:hover {
  text-decoration: none;
}
li {
  list-style: none;
  margin-top: 8px;
}
.item-title a {
  display: inline-block;
  width: 77%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content1 {
  width: 82%;
  margin: 0 auto;
  /* background-color: #eee; */
}
.intro {
  /* margin-top: 66px; */
  /* padding-right: 60px; */
  width: 45%;
  /* height: 300px; */
  float: left;
  /* margin-bottom: 52px; */
}
/* * {
    font-family: "SimHei","SimHei", Arial, Helvetica, sans-serif;
} */
.intro p {
  text-indent: 2em;
  font-size: 18px;
  line-height: 26px;
  /* margin-top: 55px; */
  margin-left: 5px;
  color: #666;
  letter-spacing: 0.03em;
  text-align: justify;
}
.intro_text{
  text-indent: 0.45em;
  font-size: 60px;
  line-height: 26px;
  /* margin-top: 55px; */
  margin-left: 5px;
  color: #666;
  letter-spacing: 0.03em;
  text-align: justify;
}
.intro_text h3{
  color: #333;
  text-indent: 0em;
  text-align: left;
  margin-bottom: 34px;
}
.intro_honor,
.move_author {
  display: flex;
  text-align: center;
  align-items: center;
  margin-top: 68px;
}
.move h3 {
  text-align: left;
  margin-bottom: 34px;
}
.move_author {
  margin-top: 68px;
}

.intro_text .secp {
  margin-top: 0;
}
@media screen and (min-width: 1360px) and (max-width: 1800px) {
  .intro p {
    font-size: 14px;
  }
  h3 {
    font-size: 26px;
  }
  .content1 {
    width: 70%;
  }
}
@media screen and (min-width: 1800px) {
  .intro p {
    font-size: 14px;
  }
  h3 {
    font-size: 32px;
  }
  .content1 {
    width: 70%;
  }
}
.honor {
  /* margin-top: 13%; */
  /* float: right; */
  width: 45%;
  display: inline-block;
  /* background: #ddd; */
  /* height: 400px; */
  margin-left: 10%;
}
.intro h3 {
  text-align: left;
  margin-bottom: 32px;
}
.honor h3 {
  text-align: center;
}
.move {
  width: 45%;
  /* height: 45%; */
  float: left;
}
.item-title {
  text-align: left;
  font-size: 16px;
}
 .move .list-content .list-item{
  margin: 28px auto;
}
 .move .list-content  .item-date{
  height: 0;
}
.move p {
  text-align: left;
  display: inline-block;
  width: 200px;
  height: 150px;
}
.list-time {
  float: right;
}
.firrow col {
  width: 750px;
}
.firrow {
  margin-bottom: 20px;
}

.content1 .author {
  width: 45%;
  /* height: 300px; */
  float: right;
  /* margin-top: 210px; */
  margin-left: 10%;
}
.pro {
  width: 100%;
  float: left;
  margin-bottom: 32px;
  margin-top: 42px;
}
.pro h3 {
  margin-bottom: 44px;
}
.pro .newPro {
  /* width: 1400px; */
  margin-top: 75px;
  margin-bottom: 20px;
  margin: 0 auto;
}
.pro .newPro .el-col {
  /* background-size: 110%; */
  transition: background-size 0.2s;
  -moz-transition: background-size 0.2s; /* Firefox 4 */
  -webkit-transition: background-size 0.2s; /* Safari 和 Chrome */
  -o-transition: background-size 0.2s; /* Opera */
}
.pro .newPro .el-col span {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 148px;
  font-size: 26px;
  opacity: 0;
  display: flex;
  color: #fff;
  justify-content: center;
  line-height: 300px;
  transition: opacity 0.2s;
  -moz-transition: opacity 0.2s; /* Firefox 4 */
  -webkit-transition: opacity 0.2s; /* Safari 和 Chrome */
  -o-transition: opacity 0.2s; /* Opera */
}
.pro .newPro .el-col:hover span {
  opacity: 1;
}
.pro .newPro .el-col:hover {
  opacity: 0.6;
  /* background-size: 101%; */
}
/* .bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 2000px;
  height: 1000px;
  background: url("../assets/img/bg1.jpg") no-repeat;
  background-size: 2000px 1200px;
  opacity: 0.2;
  z-index: -1;
} */
.el-col {
  height: 320px;
  font-size: 18px;
  text-align: center;
  display: inline-block;
}

.fircol {
  float: left;
  width: 32%;
  background: url("../assets/img/banner/01.jpg") no-repeat center center;
  background-size: 114% 333px;
  overflow: hidden;
}
.seccol {
  float: left;
  margin-left: 1.88%;
  width: 32%;
  background: url("../assets/img/banner/02.jpg") no-repeat center center;
  background-size: 108% 333px;
  overflow: hidden;
}
.thircol {
  width: 32%;
  background: url("../assets/img/banner/03.jpg") no-repeat center center;
  background-size: 108% 333px;
  overflow: hidden;
  float: right;
}

.foucol {
  float: left;
  width: 24%;
  margin-left: 0%;
  background: url("../assets/img/banner/04.jpg") no-repeat center center;
  background-size: 114% 333px;
  overflow: hidden;
}
.fifthcol {
  width: 24%;
  margin-left: 1.3%;
  background: url("../assets/img/banner/05.jpg") no-repeat center center;
  background-size: 108% 333px;
  overflow: hidden;
  float: left;
}
.sixcol {
  width: 24%;
  margin-left: 1.3%;
  background: url("../assets/img/banner/06.jpg") no-repeat center center;
  background-size: 108% 333px;
  overflow: hidden;
  float: right;
}
.sevencol {
  width: 24%;
  margin-left: 1%;
  background: url("../assets/img/banner/07.jpg") no-repeat center center;
  background-size: 108% 333px;
  overflow: hidden;
  float: right;
}
.block .list {
  width: 72%;
}

.secrow .el-col {
  height: 280px;
  /* display: flex;
  justify-content: space-evenly; */
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}

.banner .el-carousel__item .img {
  opacity: 1;
  line-height: 200px;
  margin: 0 auto;
}
.banner .el-carousel__container {
  margin-top: 8%;
  height: 200px;
}
.banner .el-carousel__item:nth-child(2n) {
  display: flex;
  justify-content: center;
}

.banner .el-carousel__item:nth-child(2n + 1) {
  display: flex;
  justify-content: center;
}
.banner .el-carousel__arrow--left {
  background: #43a3f7;
}
.banner .el-carousel__arrow--right {
  background: #43a3f7;
}
.banner .el-carousel__mask {
  background: none;
}
.main {
  width: 100%;
}

.flexr {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.banner2 {
  display: none;
}
.ewm {
  margin-top: 20px;
  width: 90px;
  height: 90px;
  text-align: center;
}
.cttitle {
  text-align: right;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}
.imgbox {
  width: 100%;
  /* height: 240px; */
  display: flex;
  background: #f1f1f1;
  /* padding: 0 20px; */
  justify-content: center;
  margin: 20px 0 10px 0;
}
.imgc {
  height: 157px;
  width: 100%;
  transition: all 0.3s;
}
.list-content {
  margin-bottom: 0;
}
.imgc:hover {
  height: 157px;
  width: 100%;
  transform: scale(1.1);
}
.imgVessel {
  width: 17%;
  min-width: 230px;
  max-width: 306px;
}
.imgVessel3 {
  display: none;
  width: 90%;
  margin: 10px auto;
}
.bannerbox {
  width: 100%;
  height: 450px;
  position: relative;
  margin-top: 26px;
}
.background {
  float: left;
  width: 100%;
  height: 100%;
  background: url(../assets/img/bg.png);
}
.bgleft {
  width: 48%;

  height: 100%;

  float: left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.date {
  width: 50px;
  height: 88px;
  background: #43a3f7;
  margin-left: 20%;
  text-align: center;
  color: #ffffff;
}
.date > .month {
  font-size: 21px;
  font-family: "MicrosoftYaHei-Bold";
  font-weight: bold;
  padding-top: 16px;
}
.date > .number {
  font-size: 29px;
  font-family: "SourceHanSansCN-heavy";
  font-weight: bold;
}
.bgright {
  width: 52%;
  height: 100%;

  float: left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.title {
  font-family: "Microsoft YaHei";
  font-size: 42px;
  margin-left: 54%;
  margin-top: -6%;
  font-weight: bold;
}
.title img {
  padding-bottom: 24px;
}
.banner {
  position: absolute;
  top: 22%;
  left: 10%;
  width: 80%;
  height: 60%;
  margin: 0 auto;
}
.banner img {
  height: 200px;
  width: 300px;
}
#startvideo {
  object-fit: cover;
}

.mediaBox {
  height: 390px;
  width: 100%;
  margin: 30px auto 10px;
}
.media {
  margin-top: 10px;
  height: 380px;
}
.calture {
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: center;
  margin-top: 20%;
}
.tip {
  font-size: 40px;
  color: #40a6fc;
  font-weight: bold;
  font-family: "Microsoft YaHei";
}
.news {
  padding: 20px 15px;
  background: #f1f1f1;
  display: none;
}
.new-text {
  z-index: 20;
  position: absolute;
  top: 50%;
  left: 17%;
  color: #fff;
}
.news > div {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  background: white;
}

@media (min-width: 1300px) {
  .mrtop3 > a > span {
    padding: 0 14px;
  }
}
@media (max-width: 1300px) {
  .navbar-right ~ .navbar-right {
    margin-right: -11px;
  }
  .title {
    margin-left: 71%;
  }
  .banner .el-carousel__item:nth-of-type(1) {
    margin-left: -22%;
    padding-top: 0;
  }
  .banner .el-carousel__item:nth-of-type(2) {
    margin-left: -8%;
    padding-top: 0;
  }
  .background {
    background-size: 100% 100%;
  }
}

.clearfix:after {
  /*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  zoom: 1; /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
}

@media (max-width: 768px) {
  .bgleft > .title {
    position: absolute;
    right: 18%;
    top: 150px;
    font-size: 42px;
  }
  .banner .el-carousel__item:nth-of-type(1) {
    transform: translateX(300.14px) scale(1) !important;

    margin-left: -290px;
    padding-top: 34px;
  }
  .banner .el-carousel__item:nth-of-type(2) {
    transform: translateX(500.14px) scale(1) !important;
    margin-left: -200px;
    padding-top: 34px;
  }
}

@media (max-width: 1150px) {
  .bannerbox {
    height: 400px;
  }
  .date {
    margin-left: 15%;
  }
  .title {
    font-size: 30px;
    margin-top: -10%;
  }
  .title img {
    padding-bottom: 24px;
  }
  .banner .el-carousel__item:nth-of-type(1) {
    margin-left: -26%;
  }
  .banner .el-carousel__item:nth-of-type(2) {
    margin-left: -10%;
  }
}

@media (max-width: 980px) {
  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
  .imgVessel2 {
    display: none;
  }
  .news {
    display: block;
  }
  .imgbox {
    background: white;
    justify-content: space-around;
  }
  .media {
    height: 400px;
  }
  .bannerbox {
    height: 380px;
  }

  .date {
    margin-left: 10%;
  }
  .title {
    margin-left: 52%;
    font-size: 32px;

    margin-top: -12%;
  }
  .title img {
    height: 58px;
  }
}
@media (max-width: 860px) {
  .bannerbox {
    height: 345px;
  }
  .title {
    margin-top: -14%;
    font-size: 26px;
  }
  .banner .el-carousel__container {
    margin-top: 8%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .media {
    float: right;
  }
}
@media (max-width: 768px) {
  .media {
    margin: 10px auto;
  }
  .calture {
    margin: 10px auto;
  }
  .ewm {
    position: absolute;
    bottom: 10%;
    right: 26%;
  }
}
@media (max-width: 720px) {
  .imgVessel {
    display: none;
  }
  .imgVessel3 {
    display: block;
  }

  .bannerbox {
    height: 340px;
  }
  .title {
    margin-top: -18%;
    font-size: 28px;
  }
}
@media (max-width: 768px) {
  .navbar-right ~ .navbar-right {
    margin-right: -6px;
  }
  .background {
    background-size: auto;
  }
}

@media (max-width: 560px) {
  .ewm {
    position: absolute;
    bottom: 12%;
    right: 5%;
  }
}
@media (max-width: 480px) {
  .banner .el-carousel__item:nth-of-type(1) {
    transform: translateX(300.14px) scale(1) !important;

    margin-left: -290px;
    padding-top: 34px;
  }
  .banner .el-carousel__item:nth-of-type(2) {
    transform: translateX(500.14px) scale(1) !important;
    margin-left: -200px;
    padding-top: 34px;
  }
  .bgleft > .title {
    right: 13%;
    top: 170px;
  }
  .bannerbox {
    height: 235px;
  }
  .banner1 {
    display: none;
  }
  .banner2 {
    display: block;
  }
  .mediaBox {
    height: 400px;
    margin: 0;
  }
  .media {
    height: 250px;
  }
  .bannerbox {
    height: 240px;
  }
  .title {
    margin-top: -38%;
    font-size: 20px;
    margin-left: 43%;
  }
  .title img {
    height: 48px;
  }
  .date {
    width: 44px;
  }
  .date > .month {
    font-size: 18px;
  }
  .date > .number {
    font-size: 20px;
  }
  .banner {
    top: 10%;
  }
  .calture {
    display: none;
  }
  .vedio {
    width: 100%;
  }

  .banner .el-carousel__container {
    margin-top: 30%;
    height: 110px;
  }
  .banner .el-carousel__item {
    height: 100px;
    width: 150px;
  }

  .banner img {
    width: 100px;
    height: 100px;
  }
  .ewm {
    margin: 5px auto;
    position: static;
  }
}
@media (max-width: 400px) {
  .banner .el-carousel__item:nth-of-type(1) {
    transform: translateX(300.14px) scale(1) !important;

    margin-left: -290px;
    padding-top: 0;
  }
  .banner .el-carousel__item:nth-of-type(2) {
    transform: translateX(500.14px) scale(1) !important;
    margin-left: -360px;
    padding-top: 0;
  }
  .bgleft > .title {
    right: 10%;
    top: 170px;
  }
  .title {
    margin-top: -39%;
    font-size: 18px;
    margin-left: 40%;
  }
  .title img {
    height: 48px;
  }
  .date {
    width: 40px;
  }
  .date > .month {
    font-size: 16px;
  }
  .date > .number {
    font-size: 18px;
  }
}
@media (max-width: 360px) {
  .bgleft > .title {
    font-size: 38px;
    right: 8%;
    top: 170px;
  }
  .banner .el-carousel__item:nth-of-type(2) {
    margin-left: -380px;
  }
}
@media (min-width: 1300px) and (max-width: 1600px) {
  .banner .el-carousel__item:nth-of-type(1) {
    margin-left: -22%;
    padding-top: 0;
  }
  .banner .el-carousel__item:nth-of-type(2) {
    margin-left: -8%;
    padding-top: 0;
  }
  .background {
    background-size: 100% 100%;
  }
}
@media (min-width: 980px) and (max-width: 1300px) {
  .bgleft {
    position: relative;
  }
  .bgleft > .title {
    position: absolute;
    left: -22%;
    top: 72px;
  }
}
</style>


