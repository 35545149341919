import Vue from 'vue';
import VueRouter from 'vue-router';
//
import Home from '../components/Home.vue';
import First from '../views/First.vue';
import introduction from '../views/businessprocess.vue';
import detail from '../views/detail.vue';
import list from '../views/list.vue';
import Second from '../views/Second.vue';
import Test from '../views/test.vue';
import Acc from '../views/acc.vue';
import showOne from '../views/showOne.vue';
import magezine from '../views/magezine.vue';
import product from '../views/productCenter.vue';
import contact from '../views/contactUs.vue';
import result from '../views/result.vue';
import zz from '../views/ZZ.vue';
import frontChip from '../components/FrontChip.vue';
import highChip from '../components/HighChip.vue';
import flipChip from '../components/FlipChip.vue';
import flipBackChip from '../components/FlipBackChip.vue';
import miniChip from '../components/MiniChip.vue';
import lampChip from '../components/LampChip.vue';
import fbChip from '../components/Fbchip.vue';
import family from '../components/family.vue';
import offer from '../components/offer.vue';
import good from '../views/good.vue';
import dressColoredChip from '../components/DressColoredChip.vue';
import digitalChip from '../components/DigitalChip.vue';

Vue.use(VueRouter);
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

const routes = [
  {
    path: '/',
    redirect: '/First'
  },
  // {
  //     path: '/',
  //     name: 'First',
  //     component: First
  // },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '自述文件'
    },
    children: [
      {
        path: '/Second',
        name: 'Second',
        component: Second,
        meta: {
          title: '测试文件'
        }
      },
      {
        path: '/First',
        name: 'First',
        component: First,
        meta: {
          title: '首页'
        }
      },


      {
        path: '/businessprocess',
        name: '',
        component: introduction,
        meta: {
          title: ''
        }
      },
      {
        path: '/detail',
        name: 'detail',
        component: detail,
        meta: {
          title: '详情'
        }
      },
      {
        path: '/productCenter',
        name: 'product',
        component: product,
        children: [
          {
            path: '/',
            redirect: '/frontChip'
          },
          {
            path: 'frontChip',
            component: frontChip
          },
          {
            path: 'highChip',
            component: highChip
          },
          {
            path: 'fbChip',
            component: fbChip
          },
          {
            path: 'flipChip',
            component: flipChip
          },
          {
            path: 'miniChip',
            component: miniChip
          },
          {
            path: 'lampChip',
            component: lampChip
          },
          {
            path: 'flipBackChip',
            component: flipBackChip
          },
          {
            path:'dressColoredChip',
            component:dressColoredChip
          },
          {
            path:'digitalChip',
            component:digitalChip
          }        
        ]     
      },
      {
        path: '/contactUs',
        name: 'contact',
        component: contact,
        meta: {
          title: '联系方式'
        }
      },
      {
        path: '/list',
        name: '',
        component: list,
        meta: {
          title: ''
        }
      },
      {
        path: '/showOne',
        name: '',
        component: showOne,
        meta: {
          title: ''
        }
      },
      {
        path: '/magezine',
        name: 'magezine',
        component: magezine,
        meta: {
          title: ''
        }
      },
      {
        path: '/good',
        component: good,
        children: [
          {
            path: '/',
            redirect: '/family'
          },
          {
            path: 'family',
            component: family
          },
          {
            path: 'offer',
            component: offer
          }]
      },
      {
        path: '/result',
        name: '',
        component: result,
        meta: {
          title: ''
        }
      },
      {
        path: '/Test',
        name: 'Test',
        component: Test,
        meta: {
          title: '测试文件'
        }
      },
      {
        path: '/Acc',
        name: 'Acc',
        component: Acc,
        meta: {
          title: '测试文件2'
        }
      }
    ]
  },
  {
    path: '/zz',
    name: 'zz',
    component: zz,
  }
];

const router = new VueRouter({
  routes
});

export default router;
