<template>
  <div style="min-height: 912px">
    <div class="block">
      <div>
        <div class="banner-box">
          <p id="p">{{ intro }}</p>
          <img v-if="pname=='新闻中心'" src="../assets/img/bg/新闻中心.jpg" alt>
          <img v-else-if="pname=='招标公告'" src="../assets/img/bg/招标公告.jpg" alt>
          <img v-else-if="pname=='招贤纳士'" src="../assets/img/bg/招贤纳士.jpg" alt>
          <img v-else-if="pname=='专题专栏'" src="../assets/img/bg/20d.jpg" alt>
          <p>{{ intro }}</p>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="list-title">
        <ol class="crumb">
          <li>
            <a href="/">首页</a>
          </li>
          <li v-if="listItem.desc != undefined">{{ listItem.desc }}</li>
        </ol>
      </div>
      <div class="list-content" v-for="citem in listItem.list" :key="citem.cId">
        <div class="list-item">
          <div class="icon">
            <img src="../assets/img/细节 (2).png" />
          </div>
          <div class="item-con">
            <div class="item-title" @click="goTo(citem.id)">
              <a>{{ citem.title }}</a>
            </div>
            <div class="content" style="color: #696969">
              {{ citem.description }}
            </div>
            <div class="item-date">
              <!-- <div class="list-time">{{ citem.createDate }}</div> -->
              <span class="list-time">{{ citem.createDate }}</span>

              <div class="detail" @click="goTo(citem.id)">
                <img src="../assets/img/add.png" />
                <a>查看详情</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-page">
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="this.pageSize"
          layout="prev, pager, next, jumper"
          :total="this.count"
        ></el-pagination>
      </div>
    </div>
    <footern></footern>
  </div>
</template>

<script>
import Footern from "../components/FooterNew";

import navbar1 from "../assets/img/img1.png";
import navbar2 from "../assets/img/navbar4.jpg";
import navbar3 from "../assets/img/navbar2.jpg";
import navbar4 from "../assets/img/navbar3.jpg";
import { articleApi } from "../api/articleList";

export default {
  name: "list",
  components: { Footern},

  data() {
    return {
      pname: "",
      
      loading: false,
      pdresult: false,
      navbar: [navbar1, navbar2, navbar3, navbar4],
      bannerHeight: "",
      screenWidth: "",
      screenHeight: "",
      cId: "",
      pageNo: 1,
      pageSize: 10,
      listItem: {},
      currentPage: 1,
      count: 10,
      intro: "",
    };
  },
  computed: {},
  created() {
    this.cId = this.$route.query.c;
    // this.cId = this.$route.query.c;
    // console.log(this.cId);
    if (
      this.cId == "C3220321663192048757" ||
      this.cId == "C3590197681053740296"
    ) {
      // console.log(1);
      this.intro = "新闻中心";
    } else if (this.cId == "C4619279028124212156") {
      this.intro = "招标公告";
    } else if (this.cId == "C9107734384465782046") {
      this.intro = "招贤纳士";
    }
    this.getArticle();
  },
  watch: {
    $route() {
      if (this.$route.query.c != undefined && this.$route.path == "/list") {
        this.cId = this.$route.query.c;
        if (
          this.cId == "C3220321663192048757" ||
          this.cId == "C3590197681053740296"
        ) {
          // console.log(1);
          this.intro = "新闻中心";
        } else if (this.cId == "C4619279028124212156") {
          this.intro = "招标公告";
        } else if (this.cId == "C9107734384465782046") {
          this.intro = "招贤纳士";
        }
        else if(this.cId == "C6312971693869261914"){
          this.intro = "专题专栏";
        }
        this.pname = this.$route.query.n;
        this.getArticle(this.cId);
        console.log('p',this.pname)
      }
    },
  },

  methods: {
    async getArticle() {
      this.loading = true;
      const result = await articleApi(this.pageNo, this.pageSize, this.cId);
      this.listItem = result;
      this.count = result.count;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getArticle();
    },
    goTo(val) {
      //直接跳转
      this.$router.push({
        path: "/detail",
        query: { id: val },
        // name: "detail",
        // params: { id: val }
      });
    },

    imgLoad() {
      this.$nextTick(() => {
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      });
    },
  },
  mounted() {
    this.pname = this.$route.query.n;
    this.pdresult = window.matchMedia("(min-width: 400px)").matches;

    this.screenWidth = document.body.clientWidth;
    this.bannerHeight = parseInt(this.screenWidth / 3.5);

    this.imgLoad();

    window.addEventListener(
      "resize",
      () => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth > 1300) {
          this.bannerHeight = parseInt(this.screenWidth / 3.5);
        } else if (this.screenWidth > 900) {
          this.bannerHeight = parseInt(this.screenWidth / 3);
        } else if (this.screenWidth > 700) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 600) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 500) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        } else if (this.screenWidth > 400) {
          this.bannerHeight = parseInt(this.screenWidth / 2.5);
        }
      },
      false
    );
  },
};
</script>
<style>
html,
* {
  padding: 0;
  margin-left: 0;
}
html,
body {
  height: 100%;
}
.flexr {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.banner2 {
  display: none;
}

.flex {
  display: flex;
  justify-content: space-between;
}
.banner-box {
  position: relative;
  height: 398px;
}
div .list{
  width: 77%;
}
.list {
  width: 86%;
  height: 100%;
  min-height: 400px;
  margin: 0 auto;
}
.design {
  height: 100%;
  width: 50%;
}
div .banner-box p {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 70px;
  letter-spacing: 6px;
  text-shadow: 2px 2px 2px #337ab7;
}
.design img {
  height: 100%;
  width: 100%;
}
.message span {
  font-size: 12px;
  color: #909090;
  font-family: "MicrosoftYaHei";
}
.message span:last-child {
  margin-left: 10px;
}
.common {
  margin-left: 6%;
  margin-top: 20px;
}
.left-btn,
.right-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.banner-title {
  font-size: 22px;
  color: #1ca4e3;
  font-family: "MicrosoftYaHei";
}
.list-item {
  /* width: 90%; */
  display: flex;
  /* padding: 10px 15px; */
  border-bottom: 1px dashed #d7d7d7;
  margin: 0 auto;
}
.list-title {
  width: 100%;
  height: 50px;
}
.crumb {
  background-color: #ffffff;
  margin-top: 10px;
  padding: 8px 15px;
  list-style: none;
  border-bottom: 1px solid #000;
}
.crumb > li {
  display: inline-block;
  font-size: 18px;
}
a {
  text-decoration: none;
}
.crumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}
.matter {
  margin-top: 20px;
  font-size: 14px;
  color: #605f5f;
}
.matter p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.list-content {
  margin-bottom: 20px;
}
.item-con {
  width: 100%;
}
.item-con a {
  cursor: pointer;
}
.item-title {
  font-size: 18px;
  margin-bottom: 5px;
}
.item-date {
  width: 100%;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.list-time {
  color: #696969;
}
.bottom-page {
  text-align: center;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination a :hover {
  background-color: #337ab7;
}
.pagination a {
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
@media (max-width: 980px) {
}
@media (max-width: 860px) {
}
@media (max-width: 768px) {
}

@media (max-width: 560px) {
}
@media (max-width: 480px) {
  .design {
    display: none;
  }
  .banner-title {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .design,
  .left-btn,
  .right-btn {
    display: none;
  }
}
</style>
